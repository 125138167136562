import React from 'react'
import {Container, Row, Col, ListGroup} from 'react-bootstrap';
import {Envelope} from 'react-bootstrap-icons';

const Contact = () =>{
	
	return(
		<div>
			<Container>
				<Row className='bg-light bg-gradient rounded'>
					<Col>
						<h1>Contact Page</h1>
						
					</Col>

				</Row>
				<Row>
					<Col><div className="my-3"><hr></hr></div></Col>
				</Row>
				<Row md='auto' className='d-flex justify-content-center'>
					<ListGroup>
						<ListGroup.Item>
							<Row className='d-flex justify-content-center'>
								<Col className='d-flex justify-content-end align-items-center'>
									<Envelope size='1.5em'/>
								</Col>
								<Col className='d-flex justify-content-start align-items-center'>
									<b>phill@alcovesun.com</b>
								</Col>
								 
							</Row>
							
						</ListGroup.Item>
					</ListGroup>
				</Row>
			</Container>
			
		</div>
	)
}

export default Contact