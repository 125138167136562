import React, {useState} from 'react';
import roleEnums from '../resources/constTypes';
import AdminRoomControls from './adminRoomControls';
import ListComponent from './listComponent';


import {Container, Row, Col, Button, Accordion, ListGroupItem, ListGroup, Card, Offcanvas, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { Gear } from 'react-bootstrap-icons';

//TODO Change name of userID to maybe clientUserData
const OpenedRoom = ({openedRoom, setOpenedRoom, openedRoomUsers, setOpenedRoomUsers, clientUserData, owner, roomUser, clientUser, chatConnection, handleLeaveRoom}) =>{

    const [showSettingControls, setShowSettingControls] = useState(false);

    const handleShowSettingsControls = () => {setShowSettingControls(true)};
    const handleHideSettingsControls = () => {setShowSettingControls(false)};



    return(
        
        <Container>
            <Row>
                <h1 className='d-inline-flex justify-content-center align-items-end' >
                {/* style={{display: "inline-flex", justifyContent: "center", alignItems: "center"}} */}
                    <u>{openedRoom.roomName}</u>
                    {
                    clientUserData.id === owner.userID ? 

                    <div className='button mx-2 d-flex align-items-end justify-content-end'>

                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip id="button-tooltip">
                                    Edit Room Settings
                                </Tooltip>
                            }
                        >
                            <Button variant='outline-info' className='button mx-2 d-flex align-items-center justify-content-center' onClick={handleShowSettingsControls}><Gear size='1em' /></Button>
                        </OverlayTrigger>

                        <Offcanvas show={showSettingControls} onHide={handleHideSettingsControls}>
                            <Offcanvas.Header closeButton><Offcanvas.Title>Room Settings</Offcanvas.Title></Offcanvas.Header>
                            <Offcanvas.Body>
                                <AdminRoomControls roomData={openedRoom} isOwnerControls={true} updateClientRoomData={setOpenedRoom}/>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>
                
                    :

                    <span></span>
                }
                </h1>
                

            </Row>
            

            {/* {
                clientUserData.id === owner.userID ? 

                <Row className='row my-2'>
                    <Button onClick={handleShowSettingsControls}>Settings</Button>
                    <Offcanvas show={showSettingControls} onHide={handleHideSettingsControls}>
                        <Offcanvas.Header closeButton><Offcanvas.Title>Room Settings</Offcanvas.Title></Offcanvas.Header>
                        <Offcanvas.Body>
                            <AdminRoomControls roomData={openedRoom} isOwnerControls={true} updateClientRoomData={setOpenedRoom}/>
                        </Offcanvas.Body>
                    </Offcanvas>
                </Row>
            
                :

                <span></span>
            } */}
            

            
            {
                //Room User
                openedRoom.roomPref.showRoomUser === true ?
                (
                    <Row className='row my-2'>
                        {/* <Card>
                            <Card.Header>Room Info</Card.Header>
                            </Card> */}
                            <Accordion defaultActiveKey='none'>
                                <Accordion.Item>
                                    <ListComponent 
                                        key={roomUser.userData.id} 
                                        user={clientUserData} 
                                        roomUser={roomUser} 
                                        openedRoomUsers={openedRoomUsers} 
                                        setOpenedRoomUsers={setOpenedRoomUsers} 
                                        chatConnection={chatConnection} 
                                        isRoomUser={true} 
                                        showRoomUserControl={
                                            
                                            roleEnums.get(clientUser.role) <= roleEnums.get(openedRoom.roomPref.roomUserAccessRights) ?
                                            (true)
                                            :
                                            (false)
                                        }
                                    />
                                </Accordion.Item>
                            </Accordion>
                            
                       
                    </Row>
                        
                    )
                    :
                    <span></span>
            }

            {/* <h3>Users</h3> */}
            <Row className='row my-2'>
                <Card>
                    <Card.Header className='d-flex justify-content-center'><h3>Users</h3></Card.Header>
                    {/* <ListGroup> */}
                    <Accordion defaultActiveKey='none' alwaysOpen>
                {
                    

                    Array.from(openedRoomUsers).map((roomUser) => {
                        const nameStyle = (roomUser.role==='OWNER') ? 'bold' : 'none';
                        if(roomUser.role!=='ROOM')
                        {
                            return (
                                <Accordion.Item eventKey={roomUser.userData.id} key={roomUser.userData.id}>
                                    <ListComponent  key={roomUser.userData.id} user={clientUserData} roomUser={roomUser} openedRoomUsers={openedRoomUsers} setOpenedRoomUsers={setOpenedRoomUsers} chatConnection={chatConnection}/>
                                </Accordion.Item>
                            )
                        }
                    })
                }
                    </Accordion>
                    {/* </ListGroup> */}
                </Card>
            </Row>
            

            <Button onClick={handleLeaveRoom}>Back To Home</Button>
        </Container>
    )
}

export default OpenedRoom;