import axios from 'axios';

const backURL =`${process.env.REACT_APP_WISHLIST_URL}/api/chatlogs`;

let token = null
const setToken = async (newToken) => {
	token = `bearer ${newToken}`

}


const createLog = async (wishlistId) => {
    const config = {
        headers: {Authorization: token},
    }
    
    
    const response = await axios.post(`${backURL}`, {targetList: wishlistId}, config);
    return response.data;
    
    
    
}

const exportModules = {createLog, setToken};
export default exportModules;