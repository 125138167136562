//Probably redundant since this is pretty much a direct copy of addItemForm, butr re-purposed for editing existing items on a list


import React,{useState} from 'react';
//import WishlistService from '../services/wishlists'

import{Form, Button, Container, Row, Col, Alert} from 'react-bootstrap';



const EditItemForm = ({handleUpdateItem, handleHideForm, itemName, setItemName, itemPrice, setItemPrice, itemHtmlLink, setItemHtmlLink, itemNotes, setItemNotes}) => {

    //These are temp states. If user cancels, original doesn't change. But when confirming change, will update original state hooks

    const [tempItemName, setTempItemName] = useState(itemName);
    const [tempItemPrice, setTempItemPrice] = useState(itemPrice);
    const [tempItemHtmlLink, setTempItemHtmlLink] = useState(itemHtmlLink);
    const [tempItemNotes, setTempItemNotes] = useState(itemNotes);





    const handleCancelChange = (event) => {
        event.preventDefault();

        //reset og hooks to the temps that held original value
        setItemName(tempItemName);
        setItemPrice(tempItemPrice);
        setItemHtmlLink(tempItemHtmlLink);
        setItemNotes(tempItemNotes);


        handleHideForm();

    }
    const handleConfirmEdit = (event) =>
    {
        event.preventDefault();

        //updates the temps that held original to new values
        setTempItemName(itemName);
        setTempItemPrice(itemPrice);
        setTempItemHtmlLink(itemHtmlLink);
        setTempItemNotes(itemNotes);

        handleUpdateItem();

        //handleHideForm();


    }


    return (

        <Form onSubmit={handleConfirmEdit}>

            <Form.Group controlId="itemNameInput">
                <Form.Label>
                    Item Name
                </Form.Label>
                <Form.Control type='text' value = {itemName} name='itemName' id='itemNameInput'
                        onChange= { ({target}) => { setItemName(target.value)} } 
                        required/>
            </Form.Group>

            <Form.Group controlId="itemPriceInput">
                <Form.Label>
                    Price
                </Form.Label>
                <Form.Control type='number' value = {itemPrice} name='itemPrice' id='itemPriceInput'
                        onChange= { ({target}) => { setItemPrice(target.value)} } />
            </Form.Group>

            <Form.Group controlId="itemLinkInput">
                <Form.Label>
                    Link
                </Form.Label>
                <Form.Control type='text' value = {itemHtmlLink} name='itemLink' id='itemLinkInput'
                        onChange= { ({target}) => { setItemHtmlLink(target.value)} }/>
            </Form.Group>

            <Form.Group controlId="itemNotesInput">
                <Form.Label>
                    Notes
                </Form.Label>
                <Form.Control as="textarea" value = {itemNotes} name='itemNotes' id='itemNotesInput'
                        onChange= { ({target}) => { setItemNotes(target.value)} }/>
            </Form.Group>

            <Container >
                <Row>
                    <Col></Col>

                    <Col className='d-flex justify-content-end'>
                        <Button className='mx-1 my-3' type='submit' id='editItemButton'>Save Item</Button>
                        <Button className='mx-1 my-3' variant='outline-primary' onClick={handleCancelChange}>Cancel</Button>
                    </Col>
                </Row>
                
            </Container>
        

        </Form>

        
    )
	
	
}


export default EditItemForm;