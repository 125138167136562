import React,{useState} from 'react';
import WishlistService from '../services/wishlists'
import ItemService from '../services/items'

import {Form, Button, Container, Row, Col, Alert} from 'react-bootstrap'






  //Handles loggining user in
const AddItemForm = ({toggleHide, targetUser, setOpenedRoomUsers, openedRoomUsers, handleHideForm}) => {

    const [itemName, setItemName] = useState('');
    const [itemPrice, setItemPrice] = useState(0);
    const [itemHtmlLink, setItemHtmlLink] = useState('');
    const [itemNotes, setItemNotes] = useState('');


   
    const handleSubmitNewItem = async (event) => {
        event.preventDefault();

        //Would need to try and submit everything, on success toggle hide and such, other wise leave error message or something
        try{

            const newWishlistItem = {
                itemName: itemName,
                price: itemPrice,
                link: itemHtmlLink,
                notes: itemNotes,
                claimers:[],
                isClaimed: false
            }

            const newItem = await ItemService.createItem(newWishlistItem);

            //Need to de-populate the wishlist so that when saving the updated one, it only saves id values of it's items
            const dePopulatedWishlist = targetUser.userData.wishlists[0].items.map((item)=>{
                return item.id;
            })

            const updatedWishlist = {...targetUser.userData.wishlists[0], items:[...dePopulatedWishlist, newItem.id]};
            const updatedFrontEndWishlist = {...targetUser.userData.wishlists[0], items:[...targetUser.userData.wishlists[0].items, newItem]};


            await WishlistService.update(targetUser.userData.wishlists[0].id, updatedWishlist);


            targetUser.userData.wishlists[0] = updatedFrontEndWishlist;

            const updatedOpenedRoomUsers = await Promise.all(openedRoomUsers.map((user) => {
                if(user.userID === targetUser.userID)
                {
                    return targetUser;
                }
                else
                {
                    return user;
                }
            }))


            setOpenedRoomUsers(updatedOpenedRoomUsers);

            //return response of the saved wishlist, which should (hopefully) be updated in App to update the display
            //TODO do this


            //Clear input fields and toggle hide
            setItemName('');
            setItemPrice(0);
            setItemHtmlLink('');
            setItemNotes('');
            toggleHide();
        }
        catch(error)
        {

            console.log('error in addinlist item!', error)
        }
        

    }

    const handleCancelChange = (event) => {
        event.preventDefault();

        setItemName('');
        setItemPrice(0);
        setItemHtmlLink('');
        setItemNotes('');
        toggleHide();



    }


    return (
        <Alert variant='dark'>
            <Alert variant='light'>

                <Form onSubmit={handleSubmitNewItem}>

                    <Form.Group controlId="itemNameInput">
                        <Form.Label>
                            Item Name
                        </Form.Label>
                        <Form.Control type='text' value = {itemName} name='itemName' id='itemNameInput'
                                onChange= { ({target}) => { setItemName(target.value)} } 
                                required/>
                    </Form.Group>

                    <Form.Group controlId="itemPriceInput">
                        <Form.Label>
                            Price
                        </Form.Label>
                        <Form.Control type='number' value = {itemPrice} name='itemPrice' id='itemPriceInput'
                                onChange= { ({target}) => { setItemPrice(target.value)} } />
                    </Form.Group>

                    <Form.Group controlId="itemLinkInput">
                        <Form.Label>
                            Link
                        </Form.Label>
                        <Form.Control type='text' value = {itemHtmlLink} name='itemLink' id='itemLinkInput'
                                onChange= { ({target}) => { setItemHtmlLink(target.value)} }/>
                    </Form.Group>

                    <Form.Group controlId="itemNotesInput">
                        <Form.Label>
                            Notes
                        </Form.Label>
                        <Form.Control as="textarea" value = {itemNotes} name='itemNotes' id='itemNotesInput'
                                onChange= { ({target}) => { setItemNotes(target.value)} }/>
                    </Form.Group>

                    <Container >
                        <Row>
                            <Col></Col>

                            <Col className='d-flex justify-content-end'>
                                <Button className='mx-1 my-3' type='submit' id='addItemButton'>Add Item</Button>
                                <Button className='mx-1 my-3' variant='outline-primary' onClick={handleCancelChange}>Cancel</Button>
                            </Col>
                        </Row>
                        
                    </Container>

                </Form>

            </Alert>
        </Alert>
        
    )
		
}


export default AddItemForm;