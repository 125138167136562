import React, {useState, useEffect, useRef} from 'react';
import ItemForm from './addItemForm'
import EditItemForm from './editItemForm'
import Togglable from './togglable'
import WishlistService from '../services/wishlists'
import ItemService from '../services/items'
import UserService from '../services/users'

import {Button, Container, Row, Col, Alert, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { XLg, PencilFill, } from 'react-bootstrap-icons';

const ItemComponent = ({newName, newPrice, newLink, newNotes, isOwn, theItem, itemOwner, user, handleDelete}) => {
    const [itemName, setItemName] = useState(newName);
    const [itemPrice, setItemPrice] = useState(newPrice);
    const [itemHtmlLink, setItemHtmlLink] = useState(newLink);
    const [itemNotes, setItemNotes] = useState(newNotes);
    const [isOwnItem, setIsOwnItem] = useState(isOwn);
    const [itemClaimers, setItemClaimers] = useState([])
    const [isItemClaimed, setIsItemClaimed] = useState(theItem.isClaimed)

    const [userClaimed, setUserClaimed] = useState(false);
    const [userGoInOn, setUserGoInOn] = useState(false);
    const [userConfirmClaim, setUserConfirmClaim] = useState(false);

    const [deleteWarning, setDeleteWarning] = useState('');

    const togglableRefEditItem = useRef()

    const togglableRefShowEditForm = useRef();
    const togglableRefShowDeletePrompt = useRef();
    const togglableRefShowEditContols = useRef();

    //wierd workaround to hide and show the duplicate entries
    //The problem with trying to render something that looks like a parent change
    const togglableRefShowEditFormWarning = useRef();
    const togglableRefShowDeletePromptWarning = useRef();
    const togglableRefShowEditControlsWarning = useRef();
    const togglableRefEditItemWarning = useRef();




    const [showAlertedTemp, setShowAlertedTemp] = useState('false');


    //Will use this to populate itemClaimers with name (and later maybe an icon if implemented) instead of their id values
	useEffect( () => {

        async function populateClaimers(){
		const claimers = await UserService.getUsers(theItem.claimers)

        const claimerNames = claimers.map((claimer)=>{
            if(claimer.id === user.id)
            {
                setUserClaimed(true);
            }
            return(
                {
                    name: claimer.name,
                    id: claimer.id
                }
            )
        })

        setItemClaimers(claimerNames)
        }populateClaimers();
		

	},[])



	

    // const toggleHideEditItem = async() => {
	// 	// togglableRefEditItem.current.toggleVisibility();
    //     const response = await togglableRefEditItemWarning.current.toggleVisibility();
    //     console.log('ToggleHideEditSection gave response', response)
	// }

    // const toggleHideEditForm = async() => {
	// 	// togglableRefShowEditForm.current.toggleVisibility();
    //     const response = await togglableRefShowEditFormWarning.current.toggleVisibility();
    //     console.log('ToggleHideEditForm gave response', response)
	// }
    
    // const toggleHideDeleteItem = async() => {
	// 	// togglableRefShowDeletePrompt.current.toggleVisibility();
    //     const response = await togglableRefShowDeletePromptWarning.current.toggleVisibility();
    //     console.log('ToggleHideEditPrompt gave response', response)

	// }

    // const toggleHideEditControls = async() => {
    //     console.log('Edit delete Controls shold be shown/hidden, previous state:', showAlertedTemp)
	// 	// togglableRefShowEditContols.current.toggleVisibility();
    //     const response = await togglableRefShowEditControlsWarning.current.toggleVisibility();
    //     console.log('ToggleHide Edit and Delete controls gave response', response)

    //     setShowAlertedTemp(response);
	// }



    const handleUpdateItem = async (item) => {

		//console.log('CHECK: event.target: ', event.target)


        

        // //Hide delete/edit buttons
        // toggleHideEditControls();

        // //Show Edit form and switch from main display to display of edit form (redundant? Think this was done to ensure the space was collapsed when empty)
		// toggleHideEditItem();
        // toggleHideEditForm();

        setDeleteWarning('warning');


        console.log('toggles', togglableRefShowEditContols.current)

	}



    const handleDeleteItem = (item, tempName) => {	


		//console.log('CHECK: event.target: ', event.target)
        setDeleteWarning('danger');

        //Hide delete/edit buttons
        // toggleHideEditControls();
        
        // //Show confirm delete/cancel buttons
        // toggleHideDeleteItem();
	}

    


    const handleCancelUpdateItem = (item) => {
	
        //reset Alerts to none, which will cause re-render of non-alert ItemBody
        setDeleteWarning('');

        //re-Show delete/edit buttons
        // toggleHideEditControls();

        // //Hide edit form, and the container where the item form would hang
		// toggleHideEditItem();
        
        // toggleHideEditForm();

	}
    const handleCancelDeleteItem = (item) => {	

		
         //reset Alerts to none, which will cause re-render of non-alert ItemBody
        setDeleteWarning('');

        //Re-show Delete/edit buttons
        // toggleHideEditControls();

        // //Hide the buttons that would delete the post
        // toggleHideDeleteItem();
	}







    // const handleConfirmUpdateItem = async (item) => {
    const handleConfirmUpdateItem = async () => {


		
          const updatedItem = 
          {
              ...theItem,
              itemName: itemName,
              price: itemPrice,
              link: itemHtmlLink,
              notes: itemNotes,
              claimers:[],
              isClaimed: false
          }
  
          const updateResponse = await ItemService.update(theItem.id, updatedItem)


          


		// toggleHideEditItem();
        // toggleHideEditForm();

        // toggleHideEditControls();

        setDeleteWarning('');

	}
    const handleConfirmDeleteItem = async () => {	

      

        const deleteResponse = await ItemService.removeItem(theItem.id)


        //Call the parent sent function for removing this item
        handleDelete(theItem, itemOwner);


        // toggleHideDeleteItem();
        // toggleHideEditControls();

        setDeleteWarning('');
	}



    
    const handleClaimItem = async () => {
        

        const updatedItem = 
          {
              ...theItem,
              claimers:[user.id],
              isClaimed: true
          }
  
          const updateResponse = await ItemService.update(theItem.id, updatedItem)

          setUserClaimed(true);
          setUserConfirmClaim(true);

          setIsItemClaimed(true);

          //TODO this is where we set the info for the user claiming. For now, placing user name, but could also do user icon and such
          setItemClaimers([...itemClaimers, 
            {
                name: user.name,
                id: user.id
              
            }
            ])


    }

    const handleUnClaimItem = async () => {
        

        const updatedItem = 
          {
              ...theItem,
              claimers:[],
              isClaimed: false
          }
  
          const updateResponse = await ItemService.update(theItem.id, updatedItem)

          setUserClaimed(false);
          setUserConfirmClaim(false);

          setIsItemClaimed(false);

          //TODO this is where we set the info for the user claiming. For now, placing user name, but could also do user icon and such
          setItemClaimers([])


    }

    const ChangeModeBody = ({hideStyle, editMode}) => {
        return(
            <div style={hideStyle !== true ? {visibility: 'visible', width:'auto', height:'auto'} : {visibility: 'hidden', width:'0', height:'0'}}>
            {
                editMode ?
                    <EditItemForm
                        handleUpdateItem={handleConfirmUpdateItem} 
                        handleHideForm={handleCancelUpdateItem}
                        itemName={itemName} 
                        setItemName={setItemName}
                        itemPrice={itemPrice} 
                        setItemPrice={setItemPrice}
                        itemHtmlLink={itemHtmlLink}
                        setItemHtmlLink={setItemHtmlLink}
                        itemNotes={itemNotes} 
                        setItemNotes={setItemNotes}
                    />
                :

                <Container className='gx-0'>
                    <Row sm={2} className='row d-flex justify-content-between gx-1'>
                        <Col>
                            <div><u>{itemName}</u></div>
                            <div>${itemPrice}</div>
                            <div><a href={`https://${itemHtmlLink}`}>{itemHtmlLink}</a></div>
                            <div>{itemNotes}</div>

                        </Col>
                        
                        <Col className='col d-flex justify-content-end align-content-start'>
                        {
                           <span></span>
                                    
                        }
                            
                        </Col>
                    </Row>

                    <Row>
                    
                    <div className='d-flex justify-content-end'>
                        <Button className='mx-1' onClick={handleConfirmDeleteItem}>Remove?</Button>
                        <Button className='mx-1' variant='outline-primary' onClick={handleCancelDeleteItem} >Cancel</Button>
                    </div>
                

                    </Row>

                </Container>

            }
            

                

            </div>
        )
        

    }

    const ItemBody = ({hideStyle, editControlRef, deletePromptRef, editFormRef, showEditFormRef, tempName}) => {

        return(
            <div style={hideStyle !== true ? {visibility: 'visible', width:'auto', height:'auto'} : {visibility: 'hidden', width:'0', height:'0'}}>
                <Container className='gx-0'>
                    <div temp={tempName}></div>
                    <Row sm={2} className='row d-flex justify-content-between gx-1'>
                        <Col>
                            <div><u>{itemName}</u></div>
                            <div>${itemPrice}</div>
                            <div><a href={`https://${itemHtmlLink}`}>{itemHtmlLink}</a></div>
                            <div>{itemNotes}</div>

                        </Col>
                        
                        <Col className='col d-flex justify-content-end align-content-start'>
                        {
                            isOwnItem ? 
                                
                                <div className='d-inline'>
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip >
                                                Add Item
                                            </Tooltip>
                                        }
                                    >
                                        <Button className='mx-1' item_info={theItem} onClick={()=> handleUpdateItem(theItem)}><PencilFill/></Button>
                                    </OverlayTrigger>
                                    

                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip >
                                                Add Item
                                            </Tooltip>
                                        }
                                    >
                                         <Button className='mx-1'item_info={theItem} onClick={()=>handleDeleteItem(theItem, tempName)}><XLg/></Button>
                                    </OverlayTrigger>
                                   
                                </div>
                                   

                            :
                            <span></span>
                            
                            }
                            
                        </Col>
                    </Row>

                    <Row>
                    {
                        isOwnItem ?
                            <span></span>
                        :
                        <div className='my-3'>
                        {
                            !isItemClaimed ?
                                <div>
                                    <Button  onClick={handleClaimItem}>Claim Item </Button>

                                {/* TODO: If got around to it, put partial go-in here as well */}
                                    
                                </div>
                            :
                                <div>
                                    <b>Claimed by: </b>
                                    {
                                        
                                        //TODO In future, maybe implement inserting user Icons isntead of names
                                        itemClaimers.map( (claimer)=> {
                                            return(<span key={claimer.id}>{claimer.name}</span>)
                                            

                                        })
                                    }
                                    {
                                        userClaimed?
                                        <div>
                                            <Button  onClick={handleUnClaimItem}>Unclaim</Button>
                                        </div> 
                                        :
                                        <span></span>
                                    }
                                </div>
                            }
                        </div>
                            
                    }         
                    </Row>

                </Container>
        
               
                
            </div>
            
        )
   


}

    // const ItemBody = ({hideStyle, editControlRef, deletePromptRef, editFormRef, showEditFormRef, tempName}) => {

    //         return(
    //             <div style={hideStyle !=='true' ? {visibility: 'visible', width:'auto', height:'auto'} : {visibility: 'hidden', width:'0', height:'0'}}>
    //                 <Togglable 
    //                 initShowContent={
    //                     (
    //                         <Container className='gx-0'>
    //                             <div temp={tempName}></div>
    //                             <Row sm={2} className='row d-flex justify-content-between gx-1'>
    //                                 <Col>
    //                                     <div><u>{itemName}</u></div>
    //                                     <div>${itemPrice}</div>
    //                                     <div><a href={`https://${itemHtmlLink}`}>{itemHtmlLink}</a></div>
    //                                     <div>{itemNotes}</div>
    
    //                                 </Col>
                                    
    //                                 <Col className='col d-flex justify-content-end align-content-start'>
    //                                 {
    //                                     isOwnItem ? 
    //                                         <Togglable
    //                                             initShowContent={
    //                                                 <div className='d-inline'>
    //                                                     <Button className='mx-1' item_info={theItem} onClick={()=> handleUpdateItem(theItem)}><PencilFill/></Button>
    //                                                     <Button className='mx-1'item_info={theItem} onClick={()=>handleDeleteItem(theItem, tempName)}><XLg/></Button>
    //                                                 </div>
    //                                             }
    //                                             initHideContent={<span></span>}
    //                                             ref={editControlRef}
    //                                         />
    
    //                                     :
    //                                     <span></span>
                                        
    //                                     }
                                        
    //                                 </Col>
    //                             </Row>
    
    //                             <Row>
    //                             <Togglable 
    //                                 initShowContent = {
    //                                     <div>
    //                                     {
    //                                         isOwnItem ?
    //                                         <span></span>
    //                                         :
    //                                         <div className='my-3'>
    //                                             {
    //                                                 !isItemClaimed ?
    //                                                 <div>
    //                                                     <Button  onClick={handleClaimItem}>Claim Item </Button>
    
    //                                                 {/* TODO: If got around to it, put partial go-in here as well */}
                                                        
    //                                                 </div>
    //                                                 :
    //                                                 <div>
    //                                                     <b>Claimed by: </b>
    //                                                     {
                                                            
    //                                                         //TODO In future, maybe implement inserting user Icons isntead of names
    //                                                         itemClaimers.map( (claimer)=> {
    //                                                             return(<span key={claimer.id}>{claimer.name}</span>)
                                                                
    
    //                                                         })
    //                                                     }
    //                                                     {
    //                                                         userClaimed?
    //                                                         <div>
    //                                                             <Button  onClick={handleUnClaimItem}>Unclaim</Button>
    //                                                         </div> 
    //                                                         :
    //                                                         <span></span>
    //                                                     }
    //                                                 </div>
    //                                             }
    //                                         </div>
                                                
    //                                     }
    
    //                                     </div>
    //                         }
    //                         initHideContent={
    //                             <div className='d-flex justify-content-end'>
    //                                 <Button className='mx-1' onClick={handleConfirmDeleteItem}>Remove?</Button>
    //                                 <Button className='mx-1' variant='outline-primary' onClick={handleCancelDeleteItem} >Cancel</Button>
    //                             </div>
    //                         }
    //                         ref={deletePromptRef}
    //                         />
    
    //                             </Row>
    
    //                         </Container>
                    
                                
    
                        
                                
            
    //                     )
                    
    //                 }
    //                 initHideContent={(
    //                     <div>
    //                         <Togglable initShowContent = {<span></span>}
    //                         initHideContent={ 
    //                                 <EditItemForm
    //                                     handleUpdateItem={handleConfirmUpdateItem} 
    //                                     handleHideForm={handleCancelUpdateItem}
    //                                     itemName={itemName} 
    //                                     setItemName={setItemName}
    //                                     itemPrice={itemPrice} 
    //                                     setItemPrice={setItemPrice}
    //                                     itemHtmlLink={itemHtmlLink}
    //                                     setItemHtmlLink={setItemHtmlLink}
    //                                     itemNotes={itemNotes} 
    //                                     setItemNotes={setItemNotes}
    //                                 />
    //                         }
    //                         ref={editFormRef}
    //                         />
    
    //                     </div>
    
    //                 )}
    //                 ref={showEditFormRef}
    //             ></Togglable>
    //             </div>
                
    //         )
       


    // }

        return(
            <div>
                <ItemBody 
                    hideStyle={ deleteWarning !=='' ? true : false} 
                    editControlRef={togglableRefShowEditContols} 
                    deletePromptRef={togglableRefShowDeletePrompt} 
                    editFormRef={togglableRefShowEditForm} 
                    showEditFormRef={togglableRefEditItem}
                    tempName='primaryItemBody'
                />

                <Alert variant={deleteWarning} style={deleteWarning !=='' ? {visibility: 'visible', width:'auto', height:'auto'} : {visibility: 'hidden', width:'0', height:'0', margin:'0', padding:'0'}}>
                    <Alert variant='light' style={deleteWarning !=='' ? {visibility: 'visible', width:'auto', height:'auto'} : {visibility: 'hidden', width:'0', height:'0', margin:'0', padding:'0'}}>
                        {/* <ChangeModeBody 
                            hideStyle={ deleteWarning !=='' ? false : true}
                            editMode={deleteWarning === 'warning' ? true : false}
                        /> */}

                        {
                            deleteWarning !=='' && deleteWarning === 'warning' ?
                                ChangeModeBody({hideStyle:false, editMode:true})
                            :
                                deleteWarning !=='' && deleteWarning !== 'warning' ?
                                    ChangeModeBody({hideStyle:false, editMode:false})
                                :
                                    ChangeModeBody({hideStyle:true, editMode:true})

                        }

                        {/* <ItemBody 
                            hideStyle={ deleteWarning !=='' ? 'false' : 'true'} style={{display: 'show'}}
                            editControlRef={togglableRefShowEditControlsWarning} 
                            deletePromptRef={togglableRefShowDeletePromptWarning} 
                            editFormRef={togglableRefShowEditFormWarning} 
                            showEditFormRef={togglableRefEditItemWarning} 
                            tempName='Alerted Item Body'
                        /> */}

                    </Alert>
                </Alert>
            </div>
            
        )


    // if(deleteWarning)
    // {
    //     return(
    //         <Alert variant={deleteWarning}>
    //             <Alert variant='light'>
    //                 {ItemBody()}

    //             </Alert>
    //         </Alert>
    //     )
       
    // }

    // else{
    //     return (
    //         <div>
    //         {
    //             ItemBody()
    //         }
    //         </div>
           
    //     )
    // }


}

export default ItemComponent;