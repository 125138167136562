import React, { useState, useEffect, useRef } from 'react'
//import _ from 'lodash'
import Togglable from './togglable'

import {Form, Button, Container, Row, Col, Card, Alert} from 'react-bootstrap';



//*** Styles ***  

// const chatBoxStyle = 
// {
// 	margin: 'auto',
// 	borderRadius: '5px',
// 	borderStyle: 'solid',
// 	borderColor: 'blue',
// 	backgroundColor: 'grey',
	
// 	marginTop: '10px',
// 	marginRight: '10px',
// 	marginBottom: '10px',
// 	marginLeft: '10px',
	
// 	paddingTop: '10px',
// 	paddingRight: '12px',
// 	paddingBottom: '10px',
// 	paddingLeft: '2px',
	
// 	width: '720px',
// 	height: '260px',
// 	overflow: 'scroll',
	
// 	display: 'flex',
// 	flexDirection: 'column-reverse'
// }

const chatBoxStyle = 
{

	
	paddingTop: '10px',
	paddingRight: '12px',
	paddingBottom: '10px',
	paddingLeft: '2px',
	
	width: 'auto',
	height: '300px',
	overflow: 'scroll',
	
	display: 'flex',
	flexDirection: 'column-reverse'
}
const chatBoxTextStyle = 
{
	// color: 'white'
	fontSize:'1.25em'
}
const chatBoxSubtextStyle = 
{
	// color: 'white',
	fontSize: '.5em',
	marginBottom: '0px'
}
const chatBoxSubtextNameStyle = 
{
	// color: 'white',
	fontSize: '1em',
}
const textAlignLeft =
{
	
	align: 'left',
	textAlign: 'left',
	marginRight: '120px',
	paddingRight: '20px',
	marginLeft: '20px',
	marginTop: '50px',
	marginBottom: '50px',
	wordWrap: 'break-word'
}
const textAlignRight =
{
	align: 'right',
	textAlign: 'right',
	marginRight: '20px',
	paddingRight: '20px',
	marginLeft: '120px',
	marginTop: '50px',
	marginBottom: '50px',
	wordWrap: 'break-word'
	
}
const hiddenListBulletStyle =
{
	listStyleType: 'none'
}



//*** Seperate Modules ***  

/*
const AlertMessage = ({isGood, message}) => {
	const alertStyle = {
		color: (isGood) ? 'green' : 'red',
		fontSize: 35,
		borderRadius: '5px',
		borderStyle: 'solid',
		borderColor: (isGood) ? 'green' : 'red',
		backgroundColor: 'grey',
		
		marginTop: '10px',
		marginRight: '10px',
		marginBottom: '10px',
		marginLeft: '10px',
		
		paddingTop: '10px',
		paddingRight: '12px',
		paddingBottom: '10px',
		paddingLeft: '2px',
	}
	const divSpacerStyle = {
		marginTop: '20px',
		marginRight: '10px',
		marginBottom: '20px',
		marginLeft: '10px'
	}
	
	if(message === null)
	{
		return(
			null
		)
	}
	
	return(
		<div style={divSpacerStyle}>
			<span style = {alertStyle}> {message} </span>
		</div>
	)
}

*/

const ChatBox = ({chatWords}) => {
	
	return(
		// <div style = {chatBoxStyle}>
		// 	<ul style = {hiddenListBulletStyle}>
		// 	{
			
			
		// 		chatWords.map( (aWord, index) => {
		// 			if(aWord.ownMessage)
		// 			{
		// 				return(
		// 					<li key = {index} style = {textAlignLeft}>
		// 						<p style = {chatBoxTextStyle}>{aWord.word}</p>
		// 						<p style = {chatBoxSubtextStyle}>{aWord.senderUserName} | {aWord.date}</p>
		// 					</li>
		// 				)
		// 			}
		// 			return(
		// 				<li key = {index} style = {textAlignRight}>
		// 					<p style = {chatBoxTextStyle}>{aWord.word}</p>
		// 					<p style = {chatBoxSubtextStyle}>{aWord.senderUserName} | {aWord.date}</p>
		// 				</li>
		// 			)
					
		// 		})
		// 	}
		// 	</ul>
		// </div>

		<Alert variant='dark'  style={chatBoxStyle}>
			{/* <ul style = {hiddenListBulletStyle}>
			{
			
			
				chatWords.map( (aWord, index) => {
					if(aWord.ownMessage)
					{
						return(
							<li key = {index} style = {textAlignLeft}>
								<p style = {chatBoxTextStyle}>{aWord.word}</p>
								<p style = {chatBoxSubtextStyle}>{aWord.senderUserName} | {aWord.date}</p>
							</li>
						)
					}
					return(
						<li key = {index} style = {textAlignRight}>
							<p style = {chatBoxTextStyle}>{aWord.word}</p>
							<p style = {chatBoxSubtextStyle}>{aWord.senderUserName} | {aWord.date}</p>
						</li>
					)
					
				})
			}
			</ul> */}

			
			{
			
			
				chatWords.map( (aWord, index) => {
					return(
						<Container>
						
							{
								aWord.ownMessage ?
								<Row>
								
									<Col className='px-0'>
									</Col>

									<Col className={aWord.ownMessage ? ' justify-content-end justify-text-end p-0' : ' justify-content-start justify-text-start p-0'}>
										<Alert variant='light' style={{width:'auto'}}>
										<p style = {chatBoxTextStyle}>{aWord.word}</p>
										</Alert>
										
									</Col>
								</Row>

								
								

							:

								<Row>

									<Col className={aWord.ownMessage ? ' justify-content-end justify-text-end p-0' : ' justify-content-start justify-text-start p-0'}>
										<Alert variant='light' style={{width:'auto'}}>
										<p style = {chatBoxTextStyle}>{aWord.word}</p>
										</Alert>
										
									</Col>

									<Col className='px-0'>
									</Col>
								</Row>

							}
							

						

						<Row>
							<Col className={aWord.ownMessage ? 'd-flex justify-content-end justify-text-end align-items-center px-0' : 'd-flex justify-content-start justify-text-start align-items-center px-0'}>
									<p className='my-0 mx-1' style = {chatBoxSubtextNameStyle}><b >{aWord.senderUserName}</b></p> | <p className='my-0 mx-1 align-items-center' style = {chatBoxSubtextStyle}>{aWord.date}</p>
							</Col>

						</Row>
						</Container>

					)
				})
			}
			
		</Alert>
	)
}






function ChatBoxComponent({discussionId, targetUserId, userId, userName, chatConnection}) {

	const [newWord, setNewWord] = useState('')
	const [currentWords, setCurrentWords] = useState([])

    const togglableRefListChat = useRef()



	useEffect(()=> {
		//send the backend the listId this chat is designated to, and have it added to this user's list of connected chat rooms
		const setupMessage = {addRoom:discussionId}
		
		chatConnection.current.send(JSON.stringify(setupMessage));
		chatConnection.current.addEventListener('message', (event) => {

			const message = JSON.parse(event.data)
			
			//Handle message interpretation here...
			if(message.error)
			{
				console.log('The sent data has an error...')
				console.log(message.error)
				

			}

			//This occurs after user submits a setup message on creation of conneciton. Sets up past messages in chat log
			else if(message.loadChatlog && message.addRoom === discussionId)
			{
				const chatLog = message.loadChatlog;

				chatLog.forEach((entry)=> {

					//Think need to reformat here
					entry.word = entry.text;
					entry.senderUserName = entry.user.userName;
					
					//And delete old data fields, it's redundant and takes up space
					delete entry.text;
					delete entry.user;

					setCurrentWords((c) => c.concat(entry))
				})



			}
			else
			{
				if(message.targetDiscussionId === discussionId)
				{
					//Concat to word box
					setCurrentWords((c) => c.concat(message))
									
					if(message.ownMessage)
					{
						setNewWord('')
					}
				}
								
			}
		}) 

	},[])


	//TODO may not need anymore, not using button to hide chat here
    const toggleHideListChat = () => {

        //TODO handle opening and closing websocket here i guess

        // if(chatConnection.readyState === WebSocket.OPEN)
        // {
        //     chatConnection.close();
        // }
        // else if(chatConnection.readyState === WebSocket.CLOSED)
        // {
        //     //TODO Maybe need to load from back end previous discussions into chat box
        //     //For now, just re-open (hopefully) the websocket connection
        //     chatConnection.current = new WebSocket(CHAT_SERVER_ADDRESS)
        // }
            

		togglableRefListChat.current.toggleVisibility();
	}
	
	

	

	
	//*** Submit Word event ***  
	const submitWordWebsocket = (event) =>{
		event.preventDefault()
		
		//Use an object to pass word, since in future could have a sentence that can be parsed into array of words
		const wordObject = {
			word: newWord,
			targetDiscussionId: discussionId,
			targetUserId: targetUserId,
			senderUserName: userName

		}
		
		//Submit the word, let server handle parsing and determining if valid, the message listener should determine how to handle any response
		chatConnection.current.send(JSON.stringify(wordObject))

	}
	
	
	
	//*** updating Input Box ***  
	const newWordChange = (event) =>{
	
    setNewWord(event.target.value)
	}
	
	
	
	return (
		// <Container>
		// 	<Row className="row d-flex justify-content-center m-0 gap-0 p-0">
		// 		<ChatBox chatWords = {currentWords}/>
		// 	</Row>
		// 	<Row className="row d-flex justify-content-center">
		// 		<Col md={5} className='col d-flex justify-content-center'>
		// 			<Form onSubmit={submitWordWebsocket}>
		// 				<Form.Group>
							
		// 					<Form.Control value={newWord}onChange={newWordChange}/>

		// 				</Form.Group>

		// 			<Button variant="primary" id='sendMessageButton' type="submit">Send</Button>
			
				
		// 			</Form> 
		// 		</Col>

		// 		<Col>
		// 		</Col>
		// 	</Row>
			
		// </Container>

		<Card bg='light' border='primary' text='dark'>
			<Card.Body className='p-0'>
				<ChatBox chatWords = {currentWords}/>
			</Card.Body>
			<Card.Footer>
				<Row className="row d-flex justify-content-center">
					<Col  className='col d-flex justify-content-start'>
						<Form onSubmit={submitWordWebsocket}>
							<Form.Group>
								
								<Form.Control value={newWord}onChange={newWordChange}/>

							</Form.Group>

						<Button className='my-1' variant="primary" id='sendMessageButton' type="submit">Send</Button>
				
					
						</Form> 
					</Col>

					<Col>
					</Col>
				</Row>

			</Card.Footer>
		</Card>

                   
			
			
			
	);
}

export default ChatBoxComponent;
