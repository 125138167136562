import React from 'react';
import FormGroup from 'react-bootstrap/esm/FormGroup';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Container, Row, Col } from 'react-bootstrap';






  //Handles loggining user in
const LoginForm = ({setUsername, setPassword, username, password, handleLogin, toggleHide}) => {

   



    return (
        <Container className=' p-0 m-0'>
            <Row className='row d-flex justify-content-center align-items-center '>
                <Col className='col justify-content-center align-items-center'> 
                {/* className='col justify-content-center align-items-center' */}
                <h2>Login</h2>
                </Col>
            </Row>
            <Row className='row d-flex justify-content-center align-items-center g-0 p-0 m-0'>
                <Col>
                    <Form onSubmit={handleLogin}>
                        <FormGroup controlId="usernameInput">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" value = {username} placeholder="" onChange={ ({target}) => { setUsername(target.value)} } />
                            {/* <Form.Text className="text-muted">
                           
                            </Form.Text> */}
                        </FormGroup>

                        <FormGroup controlId="passwordInput">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" value = {password} placeholder="" onChange={ ({target}) => { setPassword(target.value)} } />
                            {/* <Form.Text className="text-muted">
                           
                            </Form.Text> */}
                        </FormGroup>

                        <Container fluid className='mt-3'>
                            <Row className='row justify-content-between align-content-center gap-5'>
                                <Col className=" d-flex col justify-content-start">
                                    <Button variant="primary" type="submit">Login</Button>
                                </Col>
                                <Col className=" d-flex col justify-content-end px-auto">
                                    <Button variant='link' onClick={() => {toggleHide(); setUsername(''); setPassword('');}} >Signup for account</Button>
                                </Col>
                            

                            </Row>

                        </Container>
                        
                    </Form>
                </Col>
            </Row>
        </Container>
            
      
            
        
    )
	
	
}


export default LoginForm;