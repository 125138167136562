
import axios from 'axios';

const loginURL = `${process.env.REACT_APP_WISHLIST_URL}/api/login`;
const tokenCheckURL = `${process.env.REACT_APP_WISHLIST_URL}/api/login_token_check`;

let token = null
const setToken = async (newToken) => {
	token = `bearer ${newToken}`

  
}

const login = async (credentials) => {
  
  //only have to pass via post credentials, and get response, which is either error or token for authentication

  const response = await axios.post(loginURL, credentials);


  return response.data;
   

}

const checkValidToken = async () => {

  const config = {
    headers: {Authorization: token},
  }

  // const response = await 
  try{
    const response = await axios.post(tokenCheckURL, {} , config)

      return new Promise((resolve, reject) => {
        if(response.status === 401)
        {
          reject('401, bad token');     
        }
        else{
          resolve('I Should be resolved response.........');
        }
      })

  }
  catch(error){
    console.log('Error in check valid token!', error.response)
    return error;
  }
      
    
    
}


const exportModules = {login, setToken, checkValidToken};
export default exportModules;