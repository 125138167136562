//Using this to handle (for now only) enum and types. Mainly now the enum for User-role, and would
//normally for simplicity only declare in each relevant class (in this case, just adminRoomControls).
//However, in the event multiple files need constants and such, and not want to use .env, will use this file 
//to handle this enum and other such things like typedef and constants


const roleEnums = new Map([["OWNER", 0],["ADMIN", 1],["MODERATOR", 2],["USER", 3]]);
    
    
    
    


export default roleEnums;