import React from 'react';
import {Form, FormGroup, Button, Container, Row, Col} from 'react-bootstrap';

const AddRoomForm = ({roomName, setRoomName, roomPassword, setRoomPassword, handleAddRoom, toggleHide }) => {

   
    return (
       
        <Form onSubmit={handleAddRoom}>
            <FormGroup controlId="roomNameInput">
                <Form.Label>Room Name</Form.Label>
                <Form.Control type="text" value = {roomName} placeholder="" onChange={ ({target}) => { setRoomName(target.value)} } />
                {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
                </Form.Text> */}
            </FormGroup>

            <FormGroup controlId="passwordInput">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" value = {roomPassword} placeholder="" onChange={ ({target}) => { setRoomPassword(target.value)} } />
                {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
                </Form.Text> */}
            </FormGroup>
            <Container fluid className='mt-3'>
                <Row className='row justify-content-between align-content-center gap-5'>
                    <Col className=" d-flex col justify-content-start">
                        <Button variant="primary" id='addRoomButton' type="submit"> Add Room</Button>
                    </Col>
                    <Col className=" d-flex col justify-content-end">
                        <Button variant='link' onClick={toggleHide} >Cancel</Button>
                    </Col>
                   

                </Row>

            </Container>
            

        </Form>
    )
	
	
}


export default AddRoomForm;