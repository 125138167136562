import React from 'react';
import { Card, CardGroup, Col, Container, Row, Button, Carousel, CarouselItem } from 'react-bootstrap';

import Weather from '../weather/Weather';
import Wishlist from '../wishlist/src/App';

const AppCardComponent = ({targetApp}) =>{

    const switchApp = (targetApp)=>{
        switch(targetApp){
            case 'Weather':
            {
                return makeAppCard('Country/Weather Api App','Search Current Weather Stats by Country. (WARNING!) This App may not work in firefox due to security settings and calls to the weather API. Consider using an alternative browser, or change browser settings to allow mixed http/https calls.', Weather);
            }
            case 'Wishlist':
            {
                return makeAppCard('Wishlist App WIP','Users can create a profile, login, and create/join chat rooms for organizing wishlists. Basic functionality includes creating rooms, joining rooms, and providing bare bones settings for displaying the room created by user. This project is a work in progress and may have a few missing featues and some hidden bugs', Wishlist);
            }
            default:
            {
                return ;
            }
        }
    }
   
    const makeAppCard = (headerText, description, TargetAppComponent) => {
        return(
            <Card style={{width:'100%'}}>
                <Card.Header>{headerText}</Card.Header>
                <Card.Body>
                    <Card.Text>
                        <p>{description}</p>
                        <hr></hr>
                    </Card.Text>
                    <TargetAppComponent />
                </Card.Body>
            </Card>
        )
    }

    return(
        <div>
            {
                switchApp(targetApp)
            }
        </div>
    )

}

export default AppCardComponent;