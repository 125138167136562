
import axios from 'axios';

const backURL = `${process.env.REACT_APP_WISHLIST_URL}/api/listrooms`;


//Will need to set token for authentication when creating/handling rooms
let token = null
const setToken = async (newToken) => {
	token = `bearer ${newToken}`
}

const updateRoom = async (id, newRoomInfo) => {

    const config = {
        headers: {Authorization: token},
    }


    //only have to pass via post credentials, and get response, which is either error or token for authentication

    const response = await axios.put(`${backURL}/${id}`, newRoomInfo, config);

    return response.data;
   

}

const addRoom = async (newRoomInfo) => {

    const config = {
        headers: {Authorization: token},
    }


    //only have to pass via post credentials, and get response, which is either error or token for authentication

    const response = await axios.post(backURL, newRoomInfo, config);

    return response.data;
   

}

const getRoom = async (id) => {

    //TODO may need authorization for get, since rooms could possibly be private

    const response = await axios.get(`${backURL}/${id}`);


    return response.data;

}

const joinRoom = async (roomcode, password) => {
    const config = {
        headers: {Authorization: token},
    }

  

    const response = await axios.put(`${backURL}/roomcode/${roomcode}`, {password:password}, config);



    return response.data;
}

const exportModules = {addRoom, setToken, getRoom, joinRoom, updateRoom};
export default exportModules;