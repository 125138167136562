import React from 'react';
import {Link} from 'react-router-dom';

// import 'bootstrap/dist/css/bootstrap.min.css'
// import '../customCSS.css'
import Button from 'react-bootstrap/Button';
import { ButtonGroup, Col, Container, Nav, NavItem, Row } from 'react-bootstrap';

//Custom .scss that i make?
//import '../scss/customStyle.scss'

//import { Navbar } from 'react-bootstrap'


const Header = () => {

	return(
		<Container className='mx-1 my-2'>
			<h1>Alcove Sun</h1>

			
		</Container>
		
	)
}

export default Header;