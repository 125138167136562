//Copy of the basic alert message for response to user actions, such as errors or loggin sucess

import React from 'react';
import {Alert} from 'react-bootstrap';


const AlertMessage = ({alertMessage, alertTypeGood}) => {
	
	if( alertMessage === null)
	{
		return(
			null
		)
	}
	
	return(
		<Alert variant={(alertTypeGood) ? 'success' : 'danger'} className='d-felx justify-content-center'>
			<Alert.Heading>{(alertTypeGood) ? '' : 'ERROR'}</Alert.Heading>
			<p>{alertMessage}</p>
		</Alert>
	)
};

export default AlertMessage;