import React from 'react'
import {Link} from 'react-router-dom'

// import 'bootstrap/dist/css/bootstrap.min.css'
// import '../customCSS.css'
import Button from 'react-bootstrap/Button'
import { ButtonGroup, Col, Container, Nav, NavItem, Row } from 'react-bootstrap'

//Custom .scss that i make?
//import '../scss/customStyle.scss'

import { Navbar } from 'react-bootstrap'


const NavigationBar = () => {

	return(
		<div>
			<Navbar expand='md' sticky='top' bg='dark' variant='dark' className='d-flex justify-content-space'>
				<Col>
				
				</Col>
				
					<Nav bg='dark' variant="tabs" defaultActiveKey="homeLink" className='mx-5'>
						<NavItem>
							<Nav.Link as={Link} to={'/'} eventKey='homeLink'>Home</Nav.Link>
						</NavItem>
							
						<NavItem>
							<Nav.Link as={Link} to={'/projects'} eventKey='projectLink'>Projects</Nav.Link>
						</NavItem>
						
						<NavItem>
							<Nav.Link as={Link} to={'/dump'} eventKey='dumpLink'>Random</Nav.Link>
						</NavItem>
						
						<NavItem>
							<Nav.Link as={Link} to={'/contact'} eventKey='contactLink'>Contact</Nav.Link>
						</NavItem>
						
						
						
					</Nav>	
					<Col>
					
					</Col>
			</Navbar>

			
		</div>
		
	)
}

export default NavigationBar