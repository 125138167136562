import React from 'react'
//Use this to embed SoundCloud tracks
import ReactSoundCloud from 'react-soundcloud-embedded'
import {Col, Container, Row, Card, Badge} from 'react-bootstrap'
import { MusicNoteBeamed } from 'react-bootstrap-icons'

const Dump = () =>{
	
	return(
		<div>

			

			<Container>
				<Row className='bg-light bg-gradient rounded'>
					<Row>
						<Col>
						<h1>Blogs and Other Junk to Stare At</h1>
						<p>Someone should really patch this up...</p>
						</Col>

					</Row>
				<Row>
					<Col><div className="my-5"><hr></hr></div></Col>
				</Row>
				</Row>
				

				<Row>
					<Col>
						<Row className="my-3 p-2 bg-light bg-gradient rounded">
							<h2 className="my-3"><MusicNoteBeamed/> <u>Recent Audio Ideas</u> <MusicNoteBeamed/></h2>
						</Row>
						<Row className='my-3'>
							<Card>
								<Card.Header>
									<h5>More Quad Mixing High Gain</h5>
								</Card.Header>

								<Card.Body>
								<p className='mx-5'>
									This page is getting a little to full, this might be the last post until I figure a more modern and automated way of adding these in the future...
								</p>
									<p>
										Bogren Digital was holding a competition for creating heavey riffs from a given drum track. I wasn't too interested in actually competing, but I did want to try and make something out of the drum track. This was the first time working with a somewhat more modern/decent drum sound, which kinda worked in my favour. After a couple iterations of general riffs, and a good deal in playing with tones the a sample result is shown below...
									</p>
									<div className='my-2'>
										<ReactSoundCloud url='https://soundcloud.com/magniumxl/bogren-contest-tone-excercise' showUser={false} height='75%' width='75%'/>
									</div>
									<hr></hr>
									<p>Again, used the Archon. And again, had a little difficulty getting the tone i really wanted, or liked in general. Either too mushy and smooth, or too thined and eq'ed out.</p>
									<p>But then, I started demoing Neural DSP's guitar VSTs, and decided to try and quadtrack with the Granophyre. The result was better than i imagined. The Granophyre gave the full body and distortion of a properly recorded tone, as well as some rawness and low end of the amp and cab. The Archon provide the smoothness and realness of a physical amp. Together, with the properly premade drum track, and some overly produced and poorly mixed bass and master, the result was a high gain tone closer to what I would like</p>
									<p>Is it super comped and mixed poorly? Of Course. But is it tonally a better high gain sound than I normally can do? You bet!</p>
									
								</Card.Body>

								<Card.Footer>
									<Badge pill bg="info">
										PRS Archon
									</Badge>
									<Badge pill bg="info">
										PRS SE Custom 24
									</Badge>
									<Badge pill bg="info">
										Tesla Opus 4
									</Badge>
									<Badge pill bg="info">
										Nerual DSP Granophyre
									</Badge>
									<Badge pill bg="info">
										Bogren Digital
									</Badge>

								</Card.Footer>
							</Card>
							
							
						</Row>

						

						<Row className='my-3'>
							<Card>
								<Card.Header>
									<h5>Dumping Synths, and More Tone Mixing</h5>
								</Card.Header>

								<Card.Body>
									<p className='mx-5'> Just a dump of some random poorly played ideas. This time, we got some more synth and digital work in the mix
									</p>
									<hr></hr>
									<p>An experiment with Vital/Helm and some digital drums (all done by hand unfortunately), and mixed with a saturated tape feel</p>

									<div className='my-2'>
										<ReactSoundCloud url='https://soundcloud.com/magniumxl/synth-test' showUser={false} height='75%' width='75%'/>
									</div>
									<p>Followed by some drumming trying out a DrumKat, with Sitala and some African/Indian Precussion samples (This time quantatized to sound like I can acutally play)</p>
									<div className='my-2'>
										<ReactSoundCloud url='https://soundcloud.com/magniumxl/african-percussion-test' showUser={false} height='75%' width='75%'/>
									</div>
									<hr></hr>
									<p>Next, a dump of some more experiments. We start with some classicy/surfy rockish tone using BlueCat Axiom and Black Rooster Cypress, followed by a tonal test of a Traynor YBA-1 Bassmaster DI and mixed to sound somewhat decent (was going for that plexi tone). Finally, an old attempt to get some decent mixed tones from the PRS Archon, playing (poorly) a part from Pull Me Under by Dream Theater</p>
									<div className='my-2'>
										<ReactSoundCloud url='https://soundcloud.com/magniumxl/blue-cat-cypress-tone-test' showUser={false} height='75%' width='75%'/>
									</div>
									<div className='my-2'>
										<ReactSoundCloud url='https://soundcloud.com/magniumxl/traynor-yba-1-test' showUser={false} height='75%' width='75%'/>
									</div>
									<div className='my-2'>
										<ReactSoundCloud url='https://soundcloud.com/magniumxl/pull-under-tone-test' showUser={false} height='75%' width='75%'/>
									</div>

								</Card.Body>

								<Card.Footer>
									<Badge pill bg="info">
										PRS Archon
									</Badge>
									<Badge pill bg="info">
										PRS SE Custom 24
									</Badge>
									<Badge pill bg="info">
										Seymour Duncan
									</Badge>
									<Badge pill bg="info">
										Synth Vsts
									</Badge>
									<Badge pill bg="info">
										Traynor Yba-1
									</Badge>
								</Card.Footer>
							</Card>
							
							
						</Row>

						<Row className='my-3'>
							<Card>
								<Card.Header>
									<h5>A Guitar Rig 5 Preset</h5>
								</Card.Header>

								<Card.Body>
									<p className='mx-5'>
										I'm always tinkering with Guitar Rig (mainly because I only used the timed demo, so I need to mess around as fast as I can each session). Most of the time, I end up tweeking things beyond resaon and in the moment think the tone is finally perfect... only to find later that my guitar sounds like hot garbage. On rare occasions, I manage to pull something interesting and worth keeping. In this instance, I managed to find two tone settings that seemed to make my Orianthi and pickups (Dimarzio Liquifire and D-sonic) in single coil mode sound decent.
									</p>
									<p>Here are some porrly played and plane examples of what took place....</p>
									<hr></hr>

									<p>First up, the high gain sounds....</p>
									<div className='my-2'>
										<ReactSoundCloud url='https://soundcloud.com/magniumxl/grig-orianthi-single-neck-tone-1' showUser={false} height='75%' width='75%'/>
									</div>
									<div className='my-2'>
										<ReactSoundCloud url='https://soundcloud.com/magniumxl/grig-orianthi-single-neck-tone-2' showUser={false} height='75%' width='75%'/>
									</div>
									<div className='my-2'>
										<ReactSoundCloud url='https://soundcloud.com/magniumxl/grig-orianthi-anotha' showUser={false} height='75%' width='75%'/>
									</div>
									<p>In general, the tone is still overly processed, and only seems decent in isolation. But in this instance it still seemed unique, especially for the interesting part...</p>
									<p>These are all using the liquifire in single coil mode. The warm, smoothness of the neck provides the body that the single coil lacks, while the coil split provides the clarity missing from humbuckers. Bridge position was a little to thin and spanky, while humbucker mode became overly saturated. Thus, in this rare instance, single coil split neck worked wonders</p>
									
									<hr></hr>
									<p>And to follow up, a little more or a crunch/drive tone...</p>

									<div className='my-2'>
										<ReactSoundCloud url='https://soundcloud.com/magniumxl/grig-orianthi-funky-single' showUser={false} height='75%' width='75%'/>
									</div>
									<p>Again, same think, neck position, liquifire in single coil mode. However, this time it just sounds like a good single coil.</p>

									<p>Again, not sure if these would work in a mix situation, but in isolation, they sound decent to my ears, and maybe with a little more tweeking, could work in an actual song situation</p>
								</Card.Body>

								<Card.Footer>
									<Badge pill bg="info">
										Guitar Rig 5
									</Badge>
									<Badge pill bg="info">
										PRS SE Orianthi
									</Badge>
									<Badge pill bg="info">
										Dimarzio Liquifire
									</Badge>
									<Badge pill bg="info">
										Dimarzio D-Sonic
									</Badge>
								</Card.Footer>
							</Card>
							
							
						</Row>
						
						<Row className='my-3'>
							<Card>
								<Card.Header>
									<h5>Clean Tone Idea Through Archon</h5>
								</Card.Header>

								<Card.Body>
									<p className='mx-5'>A quick idea that started from writing a syncopated drum part. Idea was to try and get a funky clean tone idea (as opposed to the typical habbit of having distorted guitar) DI from Amp. Alot of improvision, especially from bass part, but otherwise an intersting experiment. Can't remember, but may have used Ozone elements to help master the output. Guitar tracks could have been pulled back a bit from being to forward to the listener. This was probably the last time I used the Lundgren Black Heaven pickups, which were eventually swapped out for Seymour Duncan Black Winters. Shows their full body nature, and versitallity in things other than high gain guitar.
									</p>
									<div className='my-2'>
										<ReactSoundCloud url='https://soundcloud.com/magniumxl/clean-song-idea-wip' showUser={false} height='75%' width='75%'/>
									</div>
									
								</Card.Body>

								<Card.Footer>
									<Badge pill bg="info">
										PRS Archon
									</Badge>
									<Badge pill bg="info">
										PRS SE Custom 24
									</Badge>
									<Badge pill bg="info">
										Lundgren Black Heaven
									</Badge>
									<Badge pill bg="info">
										Epiphone Thunderbird
									</Badge>
								</Card.Footer>
							</Card>
							
							
						</Row>
						<Row className='my-3'>
						<Card>
								<Card.Header>
									<h5>Synergy SLO/Archon Quad Track</h5>
								</Card.Header>

								<Card.Body>
									<p className='mx-5'>Another attempt to try and get a decent guitar tone by quad tracking with two different amps. This surprisingly came out decent, despite poor mixing and doing the drum track using a midi keyboard. Always difficult to play consistent enough 4 times to get everything in almost in sync.</p>
									<div className='my-2'>
										<ReactSoundCloud url='https://soundcloud.com/magniumxl/slo-archon-w-drum-bass' showUser={false} height='75%' width='75%'/>
									</div>
								</Card.Body>

								<Card.Footer>
									<Badge pill bg="info">
											PRS Archon
									</Badge>
									<Badge pill bg="info">
											Synergy SLO
									</Badge>
									<Badge pill bg="info">
										PRS SE Custom 24
									</Badge>
								</Card.Footer>
							</Card>
							
						</Row>
						<Row className='my-3'>
						<Card>
								<Card.Header>
									<h5>Random Arpegiation (Loud)</h5>
								</Card.Header>

								<Card.Body>
									<p className='mx-5'>Randomly made a midi preset that would arpegiate a piano note with a wierd harmonized synth behind it. Some wierd setting made it so that depressing the sustain pedal restarted the arpegiation sequence, and thus lead to some syncopated riffs. The recording was an on the spot improv that demonstrated the sound (wish I saved the midi notes, some of the actual passages sound decent enough to work into a song)</p>
									<div className='my-2'>
										<ReactSoundCloud url='https://soundcloud.com/magniumxl/arpegiation-test' showUser={false} height='75%' width='75%'/>
									</div>
								</Card.Body>

								<Card.Footer>
									<Badge pill bg="info">
										Midi Keyboard
									</Badge>
									<Badge pill bg="info">
										Arpegiator
									</Badge>
									<Badge pill bg="info">
										Wierd
									</Badge>
									<Badge pill bg="info">
										Expierement
									</Badge>
								</Card.Footer>
							</Card>
							
						</Row>
						
						{/* <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/675301622&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true'></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/magniumxl" title="MagniumXL" target="_blank" style="color: #cccccc; text-decoration: none;">MagniumXL</a> · <a href="https://soundcloud.com/magniumxl/clean-song-idea-wip" title="Clean Song Idea wip" target="_blank" style="color: #cccccc; text-decoration: none;">Clean Song Idea wip</a></div> */}
					
					</Col>
				</Row>

				{/* <Row>
					<Col><div className="my-3"><hr></hr></div></Col>
				</Row> */}
			</Container>
			
		</div>
	)
}

export default Dump