
import axios from 'axios';

const backURL = `${process.env.REACT_APP_WISHLIST_URL}/api/users`;



let token = null
const setToken = async (newToken) => {
	token = `bearer ${newToken}`
}


const signup = async (signupInfo) => {

  //only have to pass via post credentials, and get response, which is either error or token for authentication

  const response = await axios.post(backURL, signupInfo);

  return response.data;
   

}
const createRoomUser = async (signupInfo) => {

  //only have to pass via post credentials, and get response, which is either error or token for authentication

  const response = await axios.post(`${backURL}/makeRoom`, signupInfo);

  return response.data;
}

const update = async (id, newUser) =>{

  const config = {
    headers: {Authorization: token},
}

  const response = await axios.put(`${backURL}/${id}`, newUser, config);
  return response.data;


}

const getSelf = async () =>{

  const config = {
      headers: {Authorization: token},
  }

  const response = await axios.get(`${backURL}/self`, config);
  return response.data;


}

const getUsers = async (users, isPopulating=false, populatingRoomId=null) => {

  const config = {
    headers: {Authorization: token},
  }
  if(isPopulating)
  {
    if(populatingRoomId === null)
    {
      //ERROR: trying to populate users by a room, but didn't provide the roomID
      throw new Error('ERROR: Failed to provide roomID for loading a user to the room')
    }
    config.params = {...config.params, forRoomPop:true, roomID:populatingRoomId}
  }

  // let returnList = await Promise.all(
  //    //TODO This handles the un-id'd room user, ie room chat and such. Need to give it an id of something
  //   users.filter((user) => {
  //     if (user.userID === undefined) {
  //       return false;
  //     }
  //     return true;
  //   }) 
  //   .map(async (user) => {
   
  //     const response = await axios.get(`${backURL}/${user.userID}`, config)
  //     return response.data;
  // }))


  //   return returnList;

  let returnList = await Promise.all(
      //TODO This handles the un-id'd room user, ie room chat and such. Need to give it an id of something
      //TODO Check if there's a way to implement a single call for multiple get/post requests, to make simpler on front end
      //Not a priority, since for this application users are small, however to make larger, better to pass off to back end i think

    users.map(async (user) => {
    
      const response = await axios.get(`${backURL}/${user}`, config)
      return response.data;
  }))


   return returnList;

}


//Similar to above, but for single user
const getUser = async (user, isPopulating=false, populatingRoomId=null) => {

  const config = {
    headers: {Authorization: token},
  }
  if(isPopulating)
  {
    if(populatingRoomId === null)
    {
      //ERROR: trying to populate users by a room, but didn't provide the roomID
      throw new Error('ERROR: Failed to provide roomID for loading a user to the room')
    }
    config.params = {...config.params, forRoomPop:true, roomID:populatingRoomId}
  }

 
      //TODO This handles the un-id'd room user, ie room chat and such. Need to give it an id of something
      //TODO Check if there's a way to implement a single call for multiple get/post requests, to make simpler on front end
      //Not a priority, since for this application users are small, however to make larger, better to pass off to back end i think

    
      const response = await axios.get(`${backURL}/${user}`, config)
      return response.data;

}



const exportModules = {signup, update, getSelf, setToken, getUsers, getUser, createRoomUser};
export default exportModules;