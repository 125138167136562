
import './App.css';
import {Route, Link} from 'react-router-dom'

import Home from './pages/Home'
import Contact from './pages/Contact'
import Projects from './pages/Projects'
import Dump from './pages/Dump'

import NavigationBar from './components/NavigationBar'

//Custom .scss that i make?


import 'bootstrap/dist/css/bootstrap.min.css'
import './scss/customStyle.scss'

import Fade from 'react-bootstrap/Fade'
import {Row, Col, Container } from 'react-bootstrap';

import backgroundImg from './imgs/tempBackAlt.svg';



function App() {

	// <head>
	// <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/latest/css/bootstrap.min.css"/>
	// </head>
	
  return (
	
    <div className="App" >
		
	
	<NavigationBar/>

	{/* marginBlockStart:'50px', marginBlockEnd:'20px', */}

	<Container fluid className='mx-0' style={{minHeight:'700px',  backgroundImage: `url(${backgroundImg})`, backgroundSize:'cover', backgroundAttachment:'fixed', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
		<Row className='my-2'>
			{/* <Col>
			</Col> */}

			<Col className='justify-content-center ' >
			{/* md={10} */}
				<Route exact path = '/' children={
					({match})=>{
						
						return(
						<Fade  style={match?{position:'relative'}:{position: 'fixed'}} appear={true} in={match != null} timeout={3000} mountOnEnter={true} unmountOnExit={true}><div><Home/></div></Fade>
						)
					}
					
					}/>


				<Route exact path = '/contact' children={
					({match})=>{
						
						return(
						<Fade style={match?{position:'relative'}:{position: 'fixed'}} appear={true} in={match != null} timeout={3000} mountOnEnter={true} unmountOnExit={true}><div><Contact/></div></Fade>
						)
					}
					
					}/>
				
				<Route exact path = '/projects' children={
					({match})=>{
					
						return(
						<Fade appear={true} in={match != null} timeout={3000} mountOnEnter={true} unmountOnExit={true}><div><Projects  style={match?{position:'relative'}:{position: 'fixed'}}/></div></Fade>
						)
					}
					
					}/>

				<Route exact path = '/dump' children={
					({match})=>{
						
						return(
						<Fade style={match?{position:'relative'}:{position: 'absolute'}} appear={true} in={match != null} timeout={3000} mountOnEnter={true} unmountOnExit={true}><div><Dump/></div></Fade>
						)
					}
					
					}/>
			</Col>

			{/* <Col>
					<div></div>
			</Col> */}
		</Row>
		<Row>
			<Col>
			</Col>
		</Row>
	</Container>




	
      
    </div>
  );
}

export default App;
