import React, {useState} from 'react';
import listroomService from '../services/listrooms';
import roleEnums from '../resources/constTypes';

import {Container, Row, Col, Card, Button, Form, ListGroup, Alert} from 'react-bootstrap';




//TODO when implemented in Bootstrap, maybe switch to drop down component
//TODO Not important for this personal project, but in a more developed situation, change permission control to matrix of check boxes for what certain levels can do
//TODO for now imediately change settings, but later perhaps copy edit item way of applying only after confirmation
const AdminRoomControls = ({roomData, isOwnerControls, updateClientRoomData}) => {


    // const roomPref = roomData.roomPref;

    //Extrapolate Room data here
    const [showRoomUser, setShowRoomUser] = useState(roomData.roomPref.showRoomUser);
    const [roomUserAccessRight, setRoomUserAccessRight] = useState(roomData.roomPref.roomUserAccessRights);



    const handleShowRoomUserChange = (event) => {
        setShowRoomUser(!showRoomUser);

        const updatedRoom = {...roomData, roomPref: {...roomData.roomPref, showRoomUser: !showRoomUser} }

        listroomService.updateRoom(roomData.id, updatedRoom);
        updateClientRoomData(updatedRoom);
    }

    const handleRoomUserAccessRightsChange = (event) => {
        setRoomUserAccessRight(event.target.value);

        const updatedRoom = {...roomData, roomPref: {...roomData.roomPref, roomUserAccessRights: event.target.value} }

        listroomService.updateRoom(roomData.id, updatedRoom);
        updateClientRoomData(updatedRoom);

    }


    return (
        <ListGroup>
            <ListGroup.Item className="d-flex justify-content-between align-items-start"><Alert variant='info'>Room Code:</Alert> <b>{roomData.roomCode}</b></ListGroup.Item>
            
            <ListGroup.Item className="d-flex justify-content-between align-items-start"> 
                <Form.Label>
                    <Alert variant='info'>
                        Show Room User
                    </Alert>
                </Form.Label>
                <Form.Switch checked={showRoomUser} onChange={handleShowRoomUserChange}/>
            </ListGroup.Item>

            <ListGroup.Item className="d-flex justify-content-between align-items-start"> 
                <Form.Label>
                    <Alert variant='info'>
                        Room Edit Permision Level
                    </Alert>
                </Form.Label>
                <Form.Select value={roomUserAccessRight} onChange={handleRoomUserAccessRightsChange}>
                    <option value='OWNER'>Owner</option>
                    <option value='ADMIN'>Admins</option>
                    <option value='MODERATOR'>Moderators</option>
                    <option value='USER'>Users</option>
                </Form.Select>

            </ListGroup.Item>

        </ListGroup>

        
    );

};

export default AdminRoomControls;