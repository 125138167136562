import React, { useState, useEffect } from 'react'
import axios from 'axios'


const Weather = ({capital}) => {
	
	const [currentWeather, setCurrentWeather] = useState()
	const apiKey = process.env.REACT_APP_API_KEY
	
	useEffect( () => {
		axios.get(`http://api.weatherstack.com/current
		?access_key=${apiKey}
		&query=${capital}`).then( (response) => {setCurrentWeather(response.data)})
	}, [capital])
	
	if( typeof(currentWeather) !== 'undefined' && currentWeather != null )
	{
		return(
			<div>
				<h2>Weather in {capital}</h2>
				<p><b>Temperature: </b> {currentWeather.current.temperature} celsius</p>
				
				<div>
					{currentWeather.current.weather_icons.map( (icon, index) => {
						return(
							<img key={currentWeather.current.weather_descriptions[index]} src={icon} alt="Weather Icon as {currentWeather.current.weather_descriptions[index]}" />
						)
					}
					)}
				</div>
				<p><b>Wind: </b> {currentWeather.current.wind_speed} km/hr {currentWeather.current.wind_dir} </p>
					
			</div>
		)
	}
	return(<div></div>)
		
}

const SearchResults = ({searchWord, dataList, changeSearch}) => {
	
	
	const filteredList = dataList.filter( (data)=> data.name.common.toLowerCase().includes(searchWord.toLowerCase()))
	
	const viewThisCountry = (event) => {
		changeSearch(event.target.value)
	}
	
	if(filteredList.length <= 0)
	{

		return(<div></div>)
	}
	
	else if(filteredList.length >10)
	{
		return(
		<div> Too many matches, specify another filter</div>
		)
	}
	
	else if (filteredList.length === 1)
	{

		const singleResult = filteredList[0]
		return(
			<div>
				<div>
					<h2>{singleResult.name.common}</h2>
					<p>capital {singleResult.capital[0]} </p>
					<p>population {singleResult.population} </p>
				</div>
				<div>
					<h3>languages</h3>
					<ul>
					{/* {singleResult.languages.map((language) => <li key={language.name}>{language.name} </li> )} */}
					</ul>
				</div>
				<div>
					<img src={singleResult.flag} alt="Country's flag" width="500" height="300"/>
				</div>
				
				{ 
				(singleResult.capital !== "") ? <Weather capital={singleResult.capital}/> : <></>
					
				}
				
			</div>
		)
	}
	else
	{
		const showCountry = filteredList.map(country =>{ return {countryName:country.name.common, showCountry: false} })
		return(
		<div>
			<ul>
			{
			showCountry.map((country) => {
				if(country.showCountry === false)
				{		
					return(
						<li key={country.countryName}> 
							{country.countryName}
							<button value={country.countryName} onClick = {viewThisCountry} >show</button>
								{}
						</li>

					)
				}
				else
				{
					return(
						<li key={country.countryName}>{country.countryName} asdfasdf </li>
					)
				}
			} )
			}
			</ul>
		</div>
		)
	}
	
}
const SearchField = ({searchWord, updateSearchWord}) =>{
	return(
		<div>Find Countries <input value={searchWord} onChange={updateSearchWord} /> </div>
	)
}


const App = () => {
	
	const [countryName, setCountryName] = useState('')
	const [countryList, setCountryList] = useState([])
	
	useEffect( () => {
		axios.get('https://restcountries.com/v3.1/all').then( (response) => {setCountryList(response.data)})
	}, [])
	
	
	const updateCountryName = (event) =>{
		setCountryName(event.target.value)
	}
	
	
	
	
	
	return(
	<div>
		<SearchField searchWord = {countryName} updateSearchWord = {updateCountryName}/>
		
		<SearchResults searchWord = {countryName} dataList = {countryList} changeSearch = {setCountryName}/>
	</div>
	)
}

export default App