import axios from 'axios';

const backURL = `${process.env.REACT_APP_WISHLIST_URL}/api/wishlists`;



let token = null
const setToken = async (newToken) => {
	token = `bearer ${newToken}`

}



const createWishlist = async (userID, roomID) =>{

const config = {
    headers: {Authorization: token},
}


const response = await axios.post(`${backURL}`, {userID:userID, roomID:roomID}, config);
return response.data;


}

const update = async (id, newWishlist) =>{

  const config = {
    headers: {Authorization: token},
}

  const response = await axios.put(`${backURL}/${id}`, newWishlist, config);
  return response.data;


}

/*
const getWishlist = async () =>{

  const config = {
      headers: {Authorization: token},
  }

  const response = await axios.get(`${backURL}/self`, config);
  return response.data;


}
*/
const getWishlists = async (wishlistIds) => {

  const config = {
    headers: {Authorization: token},
  }

  

  let returnList = await Promise.all(
      //TODO This handles the un-id'd room user, ie room chat and such. Need to give it an id of something
      //TODO Check if there's a way to implement a single call for multiple get/post requests, to make simpler on front end
      //Not a priority, since for this application users are small, however to make larger, better to pass off to back end i think

    wishlistIds.map(async (wishlistId) => {
    
      const response = await axios.get(`${backURL}/${wishlistId}`, config)
      return response.data;
  }))


   return returnList;

}




const exportModules = {createWishlist, update, setToken, getWishlists};
export default exportModules;