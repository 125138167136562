import React, {useState, useRef} from 'react'
import { Card, CardGroup, Alert, Col, Container, Row, Button, Carousel, Fade, Image, Ratio} from 'react-bootstrap'
// import Weather from '../weather/Weather'
import AppCardComponent from '../components/AppCardComponent'

import wExampleImg from '../imgs/appExampleImgs/weatherExampleImage.jpg'
import wishExampleImg from '../imgs/appExampleImgs/wishExampleImage.jpg'
import gitLogo from '../imgs/GitLogo/PNG/GitHub-Mark-32px.png'

const reactComponetBox = {
		margin: 'auto',
		borderRadius: '5px',
		borderStyle: 'solid',
		borderColor: 'blue',
		backgroundColor: 'grey',
		
		marginTop: '10px',
		marginRight: '10px',
		marginBottom: '10px',
		marginLeft: '10px',
		
		paddingTop: '10px',
		paddingRight: '12px',
		paddingBottom: '10px',
		paddingLeft: '12px',
		
		width: '720px',

		display: 'flex',
		justifyContent: 'center'
	}
const appDivSpacerStyle = {
	margin: 'auto',
	borderRadius: '10px',
	borderStyle: 'solid',
	borderColor: 'white',
	backgroundColor: 'white',
	
	marginTop: '20px',
	marginRight: '10px',
	marginBottom: '20px',
	marginLeft: '10px'
}
	




const Projects = () =>{
	//TODO would be better to programatically set initial app, or set to empty. For now set to Weather since all there is
	const [activeApp, setActiveApp] = useState('');
	const [highlightApp, setHighlightApp] = useState(null);

	const activeAppRef = useRef();

	const handleLoadApp = (appName) =>{
		setActiveApp(appName);
		activeAppRef?.current?.scrollIntoView({inline:'center', behavior: 'smooth' });

		setHighlightApp('success');
		const timer = setTimeout(() => {
			setHighlightApp(null);
		  }, 1500);


	}
	
	return(
			<Container>
				<Row className='bg-light bg-gradient rounded'>
					<Row >
						<Col>
							<h1>Projects Page</h1>
							<p>Please be patient, the EasyBake is old...</p>
							
						</Col>

					</Row>

					<Row className="my-3">
						<Col><div className="my-3"><hr></hr></div></Col>
					</Row>
					<Row >
						<Col><div><p> <Image src={gitLogo} circle></Image>  <a href='https://github.com/SupremeChow'>SupremeChow@github</a></p></div></Col>
					</Row>

					<Row className="my-3">
						<Col><div className="my-3"><hr></hr></div></Col>
					</Row>

					<Row >
						<Col className="my-5"><h2>Web Components</h2><p>Click on a WebComponent to try out</p></Col>
					</Row>

					<Row className="my-3">
						<Col></Col>
					</Row>
				</Row>
				

				<Row>
					{/* <Col md={2}>
					</Col> */}

					<Col className='my-1 ' style={{height:'100%'}}>
						<Carousel interval={null} className='rounded'>
							<Carousel.Item>
								<Row className='py-5 mx-4 d-flex justify-content-center'>
									<Col></Col>
									<Col sm={6} className='mx-3 d-flex justify-content-center'>
										<Card border='light' as={Button} variant='light' className='appSelectOption' style={{color:'black'}} onClick={(event)=>{handleLoadApp('Weather')}}>
											<Card.Header>Country Weather App</Card.Header>
											<Card.Body>
												<Card.Title>Search Current Weather Stats by Country</Card.Title>
												<Ratio>
													<Card.Img variant='Top' src={wExampleImg}  />
													{/* style={{height:'75%', width:'25%', objectFit:'contain'}} */}
												</Ratio>
												
												<Card.Text>

												</Card.Text>
												{/* <a className='bttn stretched-link' href='#'>A link</a> */}
											</Card.Body>
										</Card>
									</Col>
									<Col></Col>
								</Row>
							</Carousel.Item>


							<Carousel.Item>
								<Row className='py-5 mx-4 d-flex justify-content-center'>
									<Col></Col>
									<Col sm={6} className='mx-3'>
										<Card border='light' as={Button} variant='light' className='appSelectOption' style={{width:'100%', color:'black'}} onClick={(event)=>{handleLoadApp('Wishlist');}}>
											<Card.Header>Wishlist App</Card.Header>
											<Card.Body>
												<Card.Title>Create Groups to Organize Wishlists</Card.Title>
												<Ratio>
													<Card.Img variant='Top' src={wishExampleImg} />
												</Ratio>
												
												<Card.Text>

												</Card.Text>
											</Card.Body>
										</Card>
									</Col>
									<Col></Col>
								</Row>
							</Carousel.Item>



							<Carousel.Item>
								<Row className='py-5 mx-4 d-flex justify-content-center'>
									<Col></Col>
									<Col sm={6} className='mx-3'>
										<Card border='light' as={Button} variant='light' className='appSelectOption' style={{width:'100%', color:'black'}} onClick={(event)=>{console.log('The Card 2 was clicked'); setActiveApp('');}}>
											<Card.Header>Temp App Card 3</Card.Header>
											<Card.Body>
												<Card.Title>I'm Here To Take Up Space</Card.Title>
												<Ratio>
													<Card.Img variant='Top' src={wExampleImg}/>
												</Ratio>
												
												<Card.Text>
													{/* <p>More temp textadassdasd</p> */}
												</Card.Text>
											</Card.Body>
									</Card>
									</Col>
									<Col></Col>
								</Row>

							</Carousel.Item>



							<Carousel.Item>
								<Row className='py-5 mx-4 d-flex justify-content-center'>
									<Col></Col>
									<Col sm={6} className='mx-3'>
										<Card as={Button} variant='light' className='appSelectOption' style={{width:'100%', color:'black'}} onClick={(event)=>{console.log('The Card was clicked'); setActiveApp('');}}>
											<Card.Header>Temp App Card 4</Card.Header>
											<Card.Body>
												<Card.Title>IGNORE ME!</Card.Title>
												<Ratio>
													<Card.Img variant='Top' src={wExampleImg}/>
												</Ratio>
												
												<Card.Text>

												</Card.Text>
												{/* <a className='bttn stretched-link' href='#'>A link</a> */}
											</Card.Body>
										</Card>
										</Col>
									<Col></Col>
								</Row>
							</Carousel.Item>


							<Carousel.Item>
								<Row className='py-5 mx-4 d-flex justify-content-center'>
									<Col></Col>
									<Col sm={6} className='mx-3'>
										<Card as={Button} variant='light' className='appSelectOption' style={{width:'100%', color:'black'}} onClick={(event)=>{console.log('The Card 2 was clicked'); setActiveApp('');}}>
											<Card.Header>Temp App Card 5</Card.Header>
											<Card.Body>
												<Card.Title>Nothing To See Here</Card.Title>
												<Ratio>
													<Card.Img variant='Top' src={wExampleImg}/>
												</Ratio>
												
												<Card.Text>
													{/* <p>Hey o, temp text</p> */}
												</Card.Text>
											</Card.Body>
										</Card>
									</Col>
									<Col></Col>
								</Row>
							</Carousel.Item>
								

						</Carousel>
						
					</Col>

					{/* <Col md={2}>
					</Col> */}

				</Row>

				<Row>
					<Col md={1}>
					</Col>

					<Col className='col my-4 d-flex justify-content-center'>
							<div ref={activeAppRef} className={highlightApp ? 'appHighlightActive' : 'appHighlight'}>

							{/* `shadow shadow-${highlightApp} shadow-risen shadow-intensity-xl rounded` */}
					

							{/* <Card style={{width:'100%'}}>
								<Card.Header>Country/Weather Api App</Card.Header>
								<Card.Body>
									<Card.Title>Search Current Weather Stats by Country</Card.Title>
									<Card.Text>
										<p>This is a simple api app that allows the user to look up a countries by name. If a single name is chosen, or the button show is clicked, data on that country and the current weather in the capital are shown. Note that some rare instances of countries don't work. This app was made following FullStackOpen course</p>
									</Card.Text>
									<Weather />
								</Card.Body>
							</Card> */}
							{/* <Alert variant={highlightApp !== null ? highlightApp : 'light'}> */}

							
							<Fade style={activeApp!==''?{position:'relative'}:{position: 'fixed'}} appear={true} in={activeApp !== ''} timeout={3000} mountOnEnter={true} unmountOnExit={true}>
							{
								activeApp !=='' ? <div style = {reactComponetBox}><AppCardComponent targetApp={activeApp}/> </div> :  <span></span> 
							}
							</Fade>
							</div>
							{/* </Alert> */}
					

					</Col>

					<Col md={1}>
					</Col>
				</Row>


			</Container>

	)
}

export default Projects