import React from 'react';
import {Form, FormGroup, Button, Container, Row, Col} from 'react-bootstrap'






  //Handles loggining user in
const JoinRoomForm = ({setJoinRoomCode, setJoinRoomPassword, joinRoomCode, roomPassword, handleJoinRoom, toggleHide}) => {

   



    return (
        // <div>
        //     {/* <h2>Join Room</h2> */}
        //     <form onSubmit= {handleJoinRoom}>
        //         <div>
        //         <label htmlFor={"Roomcode"}>Room Code</label>
        //             <input type='text' value = {joinRoomCode} name='Roomcode' id='roomCodeInput'
        //                 onChange= { ({target}) => { setJoinRoomCode(target.value)} } 
        //             />
        //         </div>
        //         <div>
        //             <label htmlFor={"Password"}>Password</label>
        //             <input type='password' value = {roomPassword} name='Password' id='passwordInput'
        //                 onChange= { ({target}) => { setJoinRoomPassword(target.value)} } 
        //             />
        //         </div>
        //         <button type='submit' id='joinRoomButton'>JoinRoom</button>
        //     </form>
        // </div>


        <Form onSubmit={handleJoinRoom}>
            <FormGroup controlId="roomCodeInput">
                <Form.Label>Room Code</Form.Label>
                <Form.Control type="text" value = {joinRoomCode} placeholder="" onChange={ ({target}) => { setJoinRoomCode(target.value)} } />
                {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
                </Form.Text> */}
            </FormGroup>

            <FormGroup controlId="passwordInput">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" value = {roomPassword} placeholder="" onChange={ ({target}) => { setJoinRoomPassword(target.value)} } />
                {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
                </Form.Text> */}
            </FormGroup>

            <Container fluid className='mt-3'>
                <Row className='row justify-content-between align-content-center gap-5'>
                    <Col className=" d-flex col justify-content-start">
                        <Button variant="primary" id='joinRoomButton' type="submit"> Join Room </Button>
                    </Col>
                    <Col className=" d-flex col justify-content-end">
                        <Button variant='link' onClick={toggleHide} >Cancel</Button>
                    </Col>
                   

                </Row>

            </Container>
           
        </Form>
    )
	
	
}


export default JoinRoomForm;