import React, {useState, useEffect, useRef} from 'react';

import loginService from './services/login';
import userService from './services/users';
import listroomService from './services/listrooms';
import wishlistService from './services/wishlists';
import itemService from './services/items';
import chatlogService from './services/chatlogs';


import LoginForm from './components/loginComponent';
import SignupForm from './components/signup';
import AlertMessage from './components/alertMessage';
import Togglable from './components/togglable';
import AddRoomForm from './components/addRoomForm';
import JoinRoomForm from './components/joinRoomForm';
//import AddItemForm from './components/addItemForm';

import OpenedRoom from './components/openedRoom';
import ListComponent from './components/listComponent';
import AdminRoomControls from './components/adminRoomControls';
//import ItemComponent from './components/itemComponent';

//import jwt from 'json-web-token'

import roleEnums from './resources/constTypes';


//React-bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col, Button, Accordion, Card, Modal, ListGroup, ListGroupItem} from 'react-bootstrap'
import { PersonCircle } from 'react-bootstrap-icons';

//Uncomment if doing custom style (not priority now)
// import './scss/customStyle.scss'









const App = () => {

  	const [alertMessage, setAlertMessage] = useState(null)
	const [alertTypeGood, setAlertTypeGood] = useState(true)
	
	const [user, setUser] = useState(null)
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	//Might need for identifying whick list is user's, and for other admin stuff
	const [userID, setUserID] = useState('');

	const [signupName, setSignupName] = useState('')
	const [signupUsername, setSignupUsername] = useState('')
	const [signupPassword, setSignupPassword] = useState('')

	const [newRoomName, setNewRoomName] = useState('')
	const [newRoomPassword, setNewRoomPassword] = useState('')


	//TODO maybe have state of rooms and other info when user logs in
	//TODO DOUBLE CHECK IF GOOD TO HOLD ARRAY OF THE OBJECTS, OR JUST IDs. TOO MANY ROOMS COULD BE DAYA HEAVY
	const [userOwnedRooms, setUserOwnedRooms] = useState([]);
	const [userJoinedRooms, setUserJoinedRooms] = useState([]);

	//Current state for what rooms are opened, for chatting or doing things with related lists and such
	const [openedRoom, setOpenedRoom] = useState(null);
	const [openedRoomUsers, setOpenedRoomUsers] = useState([]);
	//const [openedRoomWishlists, setOpenedRoomWishlists] = useState([]);

	//For indicating that room data is ready for display
	const [roomReady, setRoomReady] = useState(false);


	//Joining a room
	const [joinRoomCode, setJoinRoomCode] = useState('')
	const [joinRoomPassword, setJoinRoomPassword] = useState('')





	//Websocket (Was initially in individual chat rooms, but looking at placing socket here, and pass single connections to rooms to handle)
	//const [chatWs, setChatConnection] = useState(null);
	const chatConnection = useRef(null)
	//const CHAT_SERVER_ADDRESS = `ws://localhost:3001/listChat/${userID.id}`
	//









	//Forward ref, used to access the function toggleVisibility() in Togglable
	const togglableRef = useRef()


	//TODO changed for bootstrap, may not need later
	//const togglableRefCreateRoom = useRef()
	const [showAddRoomForm, setShowAddRoomForm] = useState(false);


	//TODO changed for bootstrap, may not need later
	// const togglableRefJoinRoom = useRef()
	const [showJoinRoomForm, setShowJoinRoomForm] = useState(false);

	/*
	const togglableRefAddItem = useRef()
	const togglableRefListChat = useRef()

	const togglableRefEditItem = useRef()

	*/

	
// ************   STYLES   ************************

	const togglableLinkStyle = {
		color:"blue", 
		cursor:"default", 
		border:"2px solid transparent"
	}

	//Mouse css
	const mouseHover = (event) => {

		
		event.target.style.background = "rgba(25, 25, 155, .05)";
		event.target.style.color = "rgb(10, 10, 175)";
		event.target.style.border = "2px solid blue";


	}
	const mouseOff = (event) => {
		event.target.style.color = "blue";
		event.target.style.background = "none";
		event.target.style.border = "2px solid transparent";
	}


//************************************************** */
	
	
	//Effect used to trigger a room opening
	useEffect( () => {

		async function toggleRoom(){
			if(openedRoom !== null)
			{
				await prepUsers();
				setRoomReady(true);
			}
			else{
				setOpenedRoomUsers([]);
				// setRoomReady(false);
			}
		}toggleRoom()
		

	},[openedRoom])

	//Effect used to trigger a room closing
	useEffect(() => {
		if(!roomReady)
		{
			
			setOpenedRoom(null);
		}

	},[roomReady])




  
	//TODO eventually setup Effect for checking for user already logged in
	useEffect( () => {

		async function checkStoredToken(){
			const loggedUserJSON = window.localStorage.getItem('loggedInUser')
			if(loggedUserJSON)
			{
				try
				{
					loginService.setToken(JSON.parse(loggedUserJSON).token);

					let aResponse = await loginService.checkValidToken()

					if(aResponse !== '401, bad token')
					{
						
						setUser(JSON.parse(loggedUserJSON));
						// Set tokens in services here:
						const a = async()=> {
							listroomService.setToken(JSON.parse(loggedUserJSON).token);
							userService.setToken(JSON.parse(loggedUserJSON).token);
							wishlistService.setToken(JSON.parse(loggedUserJSON).token);
							itemService.setToken(JSON.parse(loggedUserJSON).token);
							chatlogService.setToken(JSON.parse(loggedUserJSON).token);
							
						}

						//TODO VVVVV WTF is this? Did something get deleted?
						await a()
						



						await handleLoadUser();
						//setUserOwnedRooms(roomList);

					}
					
				}
				catch(error)
				{

					console.log('token is expired or some other issue that forces to log out user')
					console.log(error)

					//Stored token is invalid, remove all instances here and return null (force user to log back in)
					listroomService.setToken(null);
					loginService.setToken(null);
					userService.setToken(null);
					wishlistService.setToken(null);
					itemService.setToken(null);
					chatlogService.setToken(null);
					
					//for safety
					setUser(null);

					window.localStorage.removeItem('loggedInUser');

				}
			}

		}checkStoredToken();
		 
	}, [])






	//Method for loading user info when logged in
	//TODO: for now, just get rooms

	const handleLoadUser = async () => {



		const thisUser = await userService.getSelf();

		setUserID(thisUser);






		
		//Create websocket creation, which will be passed to child chatboxes for discussions

		//const CHAT_SERVER_ADDRESS = `ws://localhost:3001/listChat/${thisUser.id}`
		const CHAT_SERVER_ADDRESS = `${process.env.REACT_APP_WISHLIST_WSS}/listChat/${thisUser.id}`

		chatConnection.current = new WebSocket(CHAT_SERVER_ADDRESS)
		
		
		
		//*** On Connection to Server ***  
		
		chatConnection.current.addEventListener('open',(event) =>{
		})  
		
		
		
		//*** On Receiving a Message From Server ***  
		
		chatConnection.current.addEventListener('message', (event) => {
			const message = JSON.parse(event.data)
			//console.log('Client received message: ', message)
			
			//Handle message interpretation here...
			if(message.error)
			{
				console.log('The sent data has an error...')
				console.log(message.error)
				

                //This is the catch that will handle any filter/censor action that was implemented in the back end
                //NOTE: For this application, it is disabled, since there doesn't seem to be a legit reason
                //To implement a filter atm for discussing about wishlists

                /*
				//if(filtred word) { trigger alert message }
				if(message.error === 'Filtered Word Detected')
				{
					const deniedWords = message.wordsChecked.filter((wordObject) => {
						return wordObject.result
					})
					.map((wordObject) => wordObject.word)
							
					const deniedString = deniedWords.toString()
					
					//Create Temporary Error Message For Denied Words
					setAlertMessage(`The words ${ deniedString } are on the filter list, and are not allowed`)
					setAlertTypeGood(false)
					
					setTimeout(() => {
						setAlertMessage(null)	
					}, 5000)
				}
                */
			}
			else
			{


				//Concat to word box
				// setCurrentWords((c) => c.concat(message))
								
				// if(message.ownMessage)
				// {
				// 	setNewWord('')
				// }


				
			}
		}) 
			
			
			
		//*** On Closing Connection to Server ***  
		chatConnection.current.addEventListener('close', (event) => { 
		})
			
		
		//*** On Error ***  
		chatConnection.current.addEventListener('error', (event) => {
			console.log('Error: could not connect to WebSocket')
			console.log(event.data)
		})





		const ownedRoomIds = thisUser.listRooms.ownedRoomIDs;
		const joinedRoomIds = thisUser.listRooms.joinedRoomIDs;

		const newRoomsOwned =  await Promise.all( ownedRoomIds.map(async (roomId) => {
			//console.log('RoomId to fetch: ', roomId);
			// roomObject = await listroomService.getRoom(roomId);
			//console.log('The assigned room object: ', roomObject);
			//return roomObject

			return await listroomService.getRoom(roomId);

		}))

		const newRoomsJoined =  await Promise.all( joinedRoomIds.map(async (roomId) => {
			//console.log('RoomId to fetch: ', roomId);
			// roomObject = await listroomService.getRoom(roomId);
			//console.log('The assigned room object: ', roomObject);
			//return roomObject

			return await listroomService.getRoom(roomId);

		}))
		

		setUserOwnedRooms(newRoomsOwned);
		setUserJoinedRooms(newRoomsJoined);

		//TODO May not need anymore, nothing should require promise after this
		return Promise.resolve(newRoomsOwned)

		//console.log('Sanity Check, did userOwnedRooms update?: ', userOwnedRooms);

		// await roomIds.forEach(async roomId => {

		// 	//console.log('Populating rooms in loop after getting ids: should have initially: ', userOwnedRooms);
		// 	roomObject = await  listroomService.getRoom(roomId);
		// 	console.log('Acuquired room...: ', roomObject);
		// 	await setUserOwnedRooms([...userOwnedRooms, roomObject]);
		// 	console.log('...and now rooms list should be updated as: ', userOwnedRooms);
		// });



		//console.log('In App, sanity check of getting rooms as array:', userOwnedRooms);


		//TODO any other load feature go here.
		//TODO when implmeneted do similar load member rooms like above

	}


  
	const handleLogin = async (event) =>
	{
		event.preventDefault()
		
		try
		{
			

			const response = await loginService.login({username, password})

			window.localStorage.setItem('loggedInUser', JSON.stringify(response))
			setUser(response)

			await listroomService.setToken(response.token);
			await userService.setToken(response.token);
			await loginService.setToken(response.token);
			await wishlistService.setToken(response.token);
			await itemService.setToken(response.token);
			await chatlogService.setToken(response.token);
			
			await handleLoadUser();




			//console.log('Response: ', response);
			//console.log('user', user);
			//blogService.setToken(response.token)

			

			
			
			setUsername('');
			setPassword('');
			
			
			setAlertMessage(`Succesfully Logged In`)
			setAlertTypeGood(true)
			setTimeout(() => {
				setAlertMessage(null)
			}, 5000)
			
		}
		catch(error)
		{
			console.log('Login error: ', error);
			console.log('Error line number:', error.lineNumber);//<<<<<< Firefox only, delete later..... TODO!!!!!!!!!!!!!!!!!!!!
			console.log('Error stack:', error.stack);//<<<<<< Firefox only, delete later..... TODO!!!!!!!!!!!!!!!!!!!!
			//Handle making error message here....
			setAlertMessage('Wrong credentials')
			setAlertTypeGood(false)
			setTimeout(() => {
				setAlertMessage(null)
			}, 5000)
		}
		
		
	}
	
	const handleLogout = async (event) =>
	{
		//don't think need to preventDefault
		window.localStorage.removeItem('loggedInUser')
		
		
		setUser(null)
		setUsername('')
		setPassword('')
		

		await setAlertMessage(`Succesfully Logged Out`)
		await setAlertTypeGood(true)
		//console.log(alertMessage);
		await setTimeout(async () => {
			await setAlertMessage(null)
		}, 5000)
		
	}



	const LoginContentDiv = () => {

		return(
			<div>
				<LoginForm  
					setUsername={setUsername} 
					setPassword={setPassword} 
					user={user} 
					username={username} 
					password={password} 
					handleLogin={handleLogin}
					toggleHide={toggleHide}
				/> 
			</div>
		);
	};




	const handleSignup = async (event) =>
	{
		event.preventDefault()
		
		try
		{
			await userService.signup({name: signupName, username: signupUsername, password: signupPassword});
			
			
			setSignupUsername('');
			setSignupPassword('');
			
			
			setAlertMessage(`New Profile Created, Please Login...`)
			setAlertTypeGood(true)
			setTimeout(() => {
				setAlertMessage(null)
			}, 5000)

			toggleHide();
			
		}
		catch(error)
		{
			//console.log('Error in signup', error.response.data)

			//Handle making error message here....
			setAlertMessage(error.response.data.error)
			setAlertTypeGood(false)
			setTimeout(() => {
				setAlertMessage(null)
			}, 5000)
		}
		
		
	}

	const SignupContentDiv = () =>{
		return(
			<div>
				<SignupForm 
					signupName={signupName} 
					setSignupName={setSignupName} 
					signupUsername={signupUsername} 
					setSignupUsername={setSignupUsername} 
					signupPassword={signupPassword} 
					setSignupPassword={setSignupPassword} 
					handleSignup={handleSignup}
					toggleHide={toggleHide}
				/>
				{/* <u><span onClick={() => {toggleHide(); setSignupName(''); setSignupUsername(''); setSignupPassword('');}} style={togglableLinkStyle} onMouseEnter={mouseHover} onMouseLeave={mouseOff}>Back to Login</span></u> */}

			</div>
		);
	}










	const handleAddRoom = async (event) => {

		event.preventDefault()

		//TODO create service that will handle accessing backend and saving room

		//ALSO!!!! Be sure to also call user (and later wishlist) services and update their fields for rooms (and wishlist)



		try
		{
			const newRoom = await listroomService.addRoom({roomName: newRoomName, password: newRoomPassword});

			//Create the RoomUser (non-human representing the room itself)
			const roomUser = await userService.createRoomUser({userName: 'ROOM', password: newRoomPassword, roomId: newRoom.id});

			
			
			//If here, should have sucessfully added a room, so add the room to the user's list

			//TODO: may need inner try-catch incase updating user goes bad

			//console.log('In App, response after listRoomService Adds room:', response);

			 const thisUserObject = await userService.getSelf();

			 //console.log('In app, after userService gets self response: ', thisUserObject);



			 //Create Wishlist before updating user
			 const ownerWishlist = await wishlistService.createWishlist(thisUserObject.id, newRoom.id)
		

			//Create Wishlist for the room
			const roomWishlist = await wishlistService.createWishlist(roomUser.id, newRoom.id)
	



			const newListWishlists = [...thisUserObject.wishlists, ownerWishlist.id];
		

			const newListOwnedRoom = [...thisUserObject.listRooms.ownedRoomIDs, newRoom.id];

			 

			const updatedUser = {...thisUserObject,
				listRooms: {
					...thisUserObject.listRooms, 
					ownedRoomIDs: newListOwnedRoom
					},
				wishlists: newListWishlists


			};

			


			await userService.update(thisUserObject.id, updatedUser);





			//Update Room user as well (just assigning a single wishlist)
			//TODO Would it still be worth setting joined room for the room user?

			const updatedRoom = {...roomUser,
				wishlists: [roomWishlist.id]
			};

			

			await userService.update(roomUser.id, updatedRoom);




			const roomUserforListroom =  
				{
					//add owner as administrator
					userID: updatedRoom.id,
					role:"ROOM",
					 //TODO Generate wishlist, for this user w/regards to this room
				};
	
			
			
			const updatedNewRoom = {...newRoom, users:[...newRoom.users, roomUserforListroom]}

			const finalRoom = await listroomService.updateRoom(newRoom.id, updatedNewRoom);
			setUserOwnedRooms([...userOwnedRooms, finalRoom]);




			//Room made, and user (TODO and eventually the room) have their wishlist created
			//Now make a chatlog regarding the newly created lists in this room
			
			chatlogService.createLog(ownerWishlist.id);
			chatlogService.createLog(roomWishlist.id);





			 //console.log('Did user UPDATE?: ', updateUserResponse);

			
			setNewRoomName('');
			setNewRoomPassword('');
			
			
			setAlertMessage(`Room Created...`)
			setAlertTypeGood(true)
			setTimeout(() => {
				setAlertMessage(null)
			}, 5000)

			toggleHideCreateRoom();
			
		}
		catch(error)
		{
			console.log('Error in room creation', error)

			//Handle making error message here....
			setAlertMessage(error.response.data.error)
			setAlertTypeGood(false)
			setTimeout(() => {
				setAlertMessage(null)
			}, 5000)
		}

	}

	const addRoomHideDiv = () => {
		return(
			// <div>
			// 	<AddRoomForm 
			// 		roomName={newRoomName}
			// 		setRoomName={setNewRoomName}
			// 		roomPassword={newRoomPassword} 
			// 		setRoomPassword={setNewRoomPassword}
			// 		handleAddRoom={handleAddRoom}
			// 	/>
			// 	<u><span onClick={toggleHideCreateRoom} style={togglableLinkStyle} onMouseEnter={mouseHover} onMouseLeave={mouseOff}>Cancel</span></u>
			// </div>

			<Modal backdrop='static' show={showAddRoomForm}>
				<Modal.Header>
					<Modal.Title>
						Create Room
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AddRoomForm 
						roomName={newRoomName}
						setRoomName={setNewRoomName}
						roomPassword={newRoomPassword} 
						setRoomPassword={setNewRoomPassword}
						handleAddRoom={handleAddRoom}
						toggleHide={toggleHideCreateRoom}
					/>
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
				
				
			</Modal>
		)
	}





	const handleJoinRoom = async (event) => {

		event.preventDefault();


		try{

			//First check if already joined to room
			const alreadyJoined = userJoinedRooms.find((room) => {
				return room.roomCode === joinRoomCode;
			})
			const alreadyOwned = userOwnedRooms.find((room) => {
				return room.roomCode === joinRoomCode;
			})

			
			if(alreadyJoined !== undefined || alreadyOwned !== undefined)
			{
				//User already joined in this room, throw error
				const alreadyJoinedError = new Error('You have already joined this room');
				alreadyJoinedError.name = 'AlreadyJoinedError';
				throw alreadyJoinedError;

			}
			
			const foundRoom = await listroomService.joinRoom(joinRoomCode, joinRoomPassword);
			



			

			//If here, this user was added to the room, now update this user's list of rooms
			//NOTE: be sure to put in seperate joinedRooms, not OwnedRooms

			const thisUserObject = await userService.getSelf();

			 //console.log('In app, after userService gets self response: ', thisUserObject);



			 //Create Wishlist before updating user... TODO eventually may need to do for room as well
			 const memberWishlist = await wishlistService.createWishlist(thisUserObject.id, foundRoom.id)
			



			setUserJoinedRooms([...userJoinedRooms, foundRoom]);



			

			 const newListWishlists = [...thisUserObject.wishlists, memberWishlist.id];


			 const newListJoinedRoom = [...thisUserObject.listRooms.joinedRoomIDs, foundRoom.id];

			 

			 const updatedUser = {...thisUserObject,
				listRooms: {
					...thisUserObject.listRooms, 
					joinedRoomIDs: newListJoinedRoom

					
				},
				wishlists: newListWishlists


			};

			const theNewUser = await userService.update(thisUserObject.id, updatedUser);


			//Now make a chatlog regarding the new list made after joining this room
			
			chatlogService.createLog(memberWishlist.id);



			setJoinRoomCode('');
			setJoinRoomPassword('');
			
			setAlertMessage(`Succesfully Joined ${foundRoom.roomName}`)
			setAlertTypeGood(true)
			setTimeout(() => {
				setAlertMessage(null)
			}, 5000)


		}
		catch(error)
		{
			console.log('error when handling join room...', error);
			if(error.name === 'AlreadyJoinedError')
			{
				setAlertMessage(`You have already joined that room`)
				setAlertTypeGood(false)
				setTimeout(() => {
					setAlertMessage(null)
				}, 5000)
			}
			

		}

	}




	const joinRoomHideDiv = () => {
		return(
			// <div>
			// 	<JoinRoomForm 
			// 		roomCode={joinRoomCode}
			// 		setJoinRoomCode={setJoinRoomCode}
			// 		roomPassword={joinRoomPassword} 
			// 		setJoinRoomPassword={setJoinRoomPassword}
			// 		handleJoinRoom={handleJoinRoom}
			// 	/>
			// 	<u><span onClick={toggleHideJoinRoom} style={togglableLinkStyle} onMouseEnter={mouseHover} onMouseLeave={mouseOff}>Cancel</span></u>
			// </div>

			<Modal backdrop='static' show={showJoinRoomForm}>
				<Modal.Header>
					<Modal.Title>
						Join Room
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<JoinRoomForm 
						roomCode={joinRoomCode}
						setJoinRoomCode={setJoinRoomCode}
						roomPassword={joinRoomPassword} 
						setJoinRoomPassword={setJoinRoomPassword}
						handleJoinRoom={handleJoinRoom}
						toggleHide={toggleHideJoinRoom}
					/>
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
				
				
			</Modal>
		)
	}




	/*

	const addItemHideDiv = (user) => {
		return(
			<div style={{marginBlock:'10px', marginLeft:'5px', borderBlock:'2px solid blue',  paddingBlock:'5px'}}>
				<AddItemForm 
					toggleHide = {toggleHideAddItem}
					targetUser = {user}
					setOpenedRoomUsers ={setOpenedRoomUsers}
					openedRoomUsers = {openedRoomUsers}
				/>
				<u><span onClick={toggleHideAddItem} style={togglableLinkStyle} onMouseEnter={mouseHover} onMouseLeave={mouseOff}>Cancel</span></u>
			</div>
		)
	}

	const editItemHideDiv = () => {
		return(
			<div style={{marginBlock:'10px', marginLeft:'5px', borderBlock:'2px solid blue',  paddingBlock:'5px'}}>
				<AddItemForm 
					toggleHide = {toggleHideEditItem}
					targetUser = {user}
					setOpenedRoomUsers ={setOpenedRoomUsers}
					openedRoomUsers = {openedRoomUsers}
				/>
				<u><span onClick={toggleHideEditItem} style={togglableLinkStyle} onMouseEnter={mouseHover} onMouseLeave={mouseOff}>Cancel</span></u>
			</div>
		)

	}


	*/







	const handleOpenRoom = (event) => {

		const roomType = event.target.getAttribute('room-role')

		let selectedRoom
		if(roomType === 'OWNER')
		{
			 selectedRoom = userOwnedRooms.find((room) => {return room.id === event.target.getAttribute('room-id')});
			 setOpenedRoom(selectedRoom);
		}
		else if(roomType === 'MEMBER')
		{
			selectedRoom = userJoinedRooms.find((room) => {return room.id === event.target.getAttribute('room-id')});
			setOpenedRoom(selectedRoom);
		}
		
		
	}

	const handleLeaveRoom = async (event) => {

		// const set

		// setOpenedRoom(null);
		setRoomReady(false);
	}

	const handleUpdateAfterRoomPrefChange = async (newRoomData) => {
		setOpenedRoom(newRoomData);
	}

	


	// ******************* TODO!!!!!!!!!!!!!!!  

	//GIVE ROOM'S AN ID

	// user._id isn't correct, there is for some reason two ids,
	//Right now, the value user.userID is correct value
	//BE SURE TO FIX THE TWO ID ISSUE AND CHANGE NAME ACCORDINGLY!!!!!!


	const prepUsers = async () => {
		const openedRoomUserIDs = openedRoom.users;
		const userIDs = await Promise.all(
			openedRoom.users.filter( (user) => {
				if (user.userID === undefined) {
					return false;
				}
				return true;
			}) 
			.map(async (user) => {
				return user.userID;
			})
		)


		//TODO 12 20 2021: trying new service method, which will ask backend to popualte specifically for populating the room, ie only name of users and filling in wishlists
		// const roomUsers = await userService.getUsers(userIDs);
		const roomUsers = await userService.getUsers(userIDs, true, openedRoom.id);


		let mergedList = new Map();



	
		roomUsers.forEach((user) => {
			if(user.id !== undefined)
			{
				mergedList.set(user.id, user) 
			}
			
		});

		// now do the "join":
		openedRoomUserIDs.forEach((user) => {

			//TODO Be sure to fix Room user wich right now isn't assinged an id
			if(user === undefined)
			{
				console.log('For some reason a user is made undefined, skipping');
				//do nothing 
				return;
			}
			if(user.userID === undefined)
			{
		
				//do nothing ? TODO VVVVV Be sure to make ROOM id and name, (perhaps just use room id and name)

				user.userData = {id:'123456789',name: 'ROOM'};
				return;
			}
			else{
				user.userData = mergedList.get(user.userID);


				// TODO hold off, trying populate ahead of time...

				// const wishlistData = wishlistService.getWishlists(user.wishlist);

				// user.wishlist = wishlistData[0];


			}

			
		});

		setOpenedRoomUsers(openedRoomUserIDs)
	}




	

	const toggleHide = () => {
		togglableRef.current.toggleVisibility();
	}

	const toggleHideCreateRoom = () => {
		//togglableRefCreateRoom.current.toggleVisibility();
		setShowAddRoomForm(!showAddRoomForm)

	}

	const toggleHideJoinRoom = () => {
		//togglableRefJoinRoom.current.toggleVisibility();
		setShowJoinRoomForm(!showJoinRoomForm)
	}


	


  	if(user === null)
	{

		return(
			<Container className=' gap-1 p-3'>
				<Row md={5}></Row>

				<Row  className='row d-flex justify-content-between align-items-center g-1 p-3 m-'>
					<Col></Col>
					
					<Col md={4}>
						<Togglable 
							initShowContent={LoginContentDiv()}
							initHideContent={SignupContentDiv()}
							ref={togglableRef}
						/>

					</Col>

				<Col></Col>
				</Row>

				<Row md={5} className='row d-flex justify-content-center align-items-center '>
					<Col></Col>
					<Col md={3} className='col d-flex justify-content-center align-items-center '>
						<AlertMessage 
							alertMessage={alertMessage} 
							alertTypeGood={alertTypeGood} 
						
						/>
					</Col>
					<Col></Col>
					
				</Row>

			</Container>
		)
		
	}
	else
	{
		if(roomReady)
		{	
			const owner = openedRoom.users.find((user) => user.role==='OWNER')
			const roomUser = openedRoom.users.find((user) => user.role==='ROOM');
			const clientUser =  openedRoom.users.find((user) => user.userID === userID.id);
			
			return(
				<Container>
					
					<Row sm={3} md={5} className="row d-flex justify-content-between align-content-center my-2">
						<Col sm={1}  className=" d-flex col justify-content-start my-2 py-2">{/* Logo Here */}</Col>
						<Col sm={1} md='auto' className=" d-flex col justify-content-center my-2 py-2">{/* Space here?????? */}</Col>
						<Col sm={1} className=" d-flex col justify-content-end my-2 py-2">	
							<Col className='d-flex align-content-center justify-content-center mx-2'>
								<PersonCircle size='2em' className='mx-1'/> <h3>{user.name}</h3>
							</Col>

							<Col className=' d-flex align-content-center justify-content-center mx-2'>
								<Button variant='secondary' onClick={handleLogout}>Logout</Button>
							</Col>
							
							
						</Col>
					</Row>
					<Row>
						<OpenedRoom 
							openedRoom={openedRoom} 
							setOpenedRoom={setOpenedRoom}
							openedRoomUsers={openedRoomUsers}
							setOpenedRoomUsers ={setOpenedRoomUsers}
							clientUserData={userID}
							owner={owner}
							roomUser={roomUser}
							clientUser={clientUser}
							chatConnection={chatConnection}
							handleLeaveRoom={handleLeaveRoom}
						/>
					</Row>
				</Container>
				
				
			)
		}
		else{

			return (
				
				<Container>
					
					<Row  md={5} className="row d-flex justify-content-between align-content-center my-2">
						<Col   className=" d-flex col justify-content-start my-2 py-2">{/* Logo Here */}</Col>
						<Col  md='auto' className=" d-flex col justify-content-center my-2 py-2">{/* Space here?????? */}</Col>
						<Col  className=" d-flex col justify-content-end my-2 py-2">	
							<Col className='d-flex align-content-center justify-content-center mx-2'>
								<PersonCircle size='2em' className='mx-1'/> <h3>{user.name}</h3>
							</Col>

							<Col className=' d-flex align-content-center justify-content-center mx-2'>
								<Button variant='secondary' onClick={handleLogout}>Logout</Button>
							</Col>
							
							
						</Col>
					</Row>

					<Row className="row justify-content-between align-content-center gap-5 my-5">
						<Col md={1}></Col>
						<Col md={4} className='col gap-2'>
							<Card>
								
								<Card.Body>
									<Card.Title className='d-flex justify-content-center'><h2>Wishists</h2></Card.Title>

									<Accordion>
										<Accordion.Item eventKey="OwnedRooms">
											<Accordion.Header>Your Rooms</Accordion.Header>
											<Accordion.Body>
												<ListGroup>	
													{
													userOwnedRooms.map(room =>{
														return(
															// <li key={room.id} room-id={room.id} room-role={"OWNER"} onClick={handleOpenRoom} onMouseEnter={mouseHover} onMouseLeave={mouseOff}>
															// {room.roomName}
															// </li>
															<ListGroup.Item action room-id={room.id} room-role={"OWNER"} onClick={handleOpenRoom}>{room.roomName}</ListGroup.Item>
														)
													})
												}
												</ListGroup>
											</Accordion.Body>
										</Accordion.Item>

										<Accordion.Item eventKey="JoinedRooms">
											<Accordion.Header>Joined Rooms</Accordion.Header>
											<Accordion.Body>
												<ListGroup>
													{
														userJoinedRooms.map(room =>{
															return(
																// <li key={room.id} room-id={room.id} room-role={"MEMBER"} onClick={handleOpenRoom} onMouseEnter={mouseHover} onMouseLeave={mouseOff}>
																// {room.roomName}
																// </li>
																<ListGroup.Item action room-id={room.id} room-role={"MEMBER"} onClick={handleOpenRoom}>{room.roomName}</ListGroup.Item>
															)
														})
													}
												</ListGroup>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>

									<Row className="row justify-content-around my-2">
										<Col className="col d-flex justify-content-center mr-1">

											{/* <Togglable 
												initShowContent={(<Button onClick={toggleHideCreateRoom}>Add Room</Button>)}
												initHideContent={addRoomHideDiv()}
												ref={togglableRefCreateRoom}
											></Togglable> */}
											<Button onClick={toggleHideCreateRoom}>Add Room</Button>
											{addRoomHideDiv()}

										</Col>
										

										

										<Col className="col d-flex justify-content-center mr-1">
											{/* <Togglable 
												initShowContent={(<Button className='mx-auto' onClick={toggleHideJoinRoom}>Join Room</Button>)}
												initHideContent={joinRoomHideDiv()}
												ref={togglableRefJoinRoom}
											>

											</Togglable> */}
											<Button onClick={toggleHideJoinRoom}>Join Room</Button>
											{joinRoomHideDiv()}
										</Col>
										
									</Row>
									
								</Card.Body>
							</Card>

							

							

						</Col>
						<Col md={1} >	
						</Col>

					</Row>

					<Row className="row justify-content-around my-2">
						<Col>

						</Col>
						<Col className="col d-flex justify-content-center mr-1">
							<AlertMessage alertMessage={alertMessage} setAlertMessage={setAlertMessage} alertTypeGood={alertTypeGood} setAlertTypeGood = {setAlertTypeGood}/>
						</Col>
						<Col>

						</Col>
					</Row>
					
					

					{/* {
						//Place main page here, where user can access their wishlist rooms, or create one, or join one if give room code and password
					}

					<h2>Your Rooms</h2>
					{
						userOwnedRooms.map(room =>{
							return(
								<li key={room.id} room-id={room.id} room-role={"OWNER"} onClick={handleOpenRoom} onMouseEnter={mouseHover} onMouseLeave={mouseOff}>
								{room.roomName}
								</li>
							)
						})
					}

					<h2>Your Joined Rooms</h2>
					{
						userJoinedRooms.map(room =>{
							return(
								<li key={room.id} room-id={room.id} room-role={"MEMBER"} onClick={handleOpenRoom} onMouseEnter={mouseHover} onMouseLeave={mouseOff}>
								{room.roomName}
								</li>
							)
						})
					}
					
					
					
					<Togglable 
						initShowContent={(<button onClick={toggleHideCreateRoom}>Add Room</button>)}
						initHideContent={addRoomHideDiv()}
						ref={togglableRefCreateRoom}
					>

					</Togglable>

					<Togglable 
						initShowContent={(<button onClick={toggleHideJoinRoom}>Join Room</button>)}
						initHideContent={joinRoomHideDiv()}
						ref={togglableRefJoinRoom}
					>

					</Togglable> */}
					
					
				</Container>
			)
		}
	}
	

	
  
}

export default App;
