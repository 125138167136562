import React, {useRef} from 'react';

import AddItemForm from './addItemForm';
import ItemComponent from './itemComponent';
import Togglable from './togglable';
import ChatBoxComponent from './chatbox';

import { Container, Row, Col, Button, Card, Accordion, Alert, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {ChatLeftDotsFill, PlusLg} from 'react-bootstrap-icons'







const ListComponent = ({user, roomUser, openedRoomUsers, setOpenedRoomUsers, chatConnection, isRoomUser, showRoomUserControl}) => {
    const togglableRefListChat = useRef()
    const togglableRefAddItem = useRef()

    const toggleHideListChat = () => {
		togglableRefListChat.current.toggleVisibility();
	}

    const toggleHideAddItem = () => {
		togglableRefAddItem.current.toggleVisibility();
	}




    const addItemHideDiv = (user) => {
		return(
			<Card className='px-1 py-1'>
				<AddItemForm 
					toggleHide = {toggleHideAddItem}
					targetUser = {user}
					setOpenedRoomUsers ={setOpenedRoomUsers}
					openedRoomUsers = {openedRoomUsers}
				/>
				{/* <<u><span> onClick={toggleHideAddItem} style={togglableLinkStyle} onMouseEnter={mouseHover} onMouseLeave={mouseOff}>Cancel</span></u> */}
                {/* <Button variant='outline-primary' onClick={toggleHideAddItem} >Cancel</Button> */}
            </Card>
		)
	}
    const chatBoxHideDiv = () => {
        return(
			<div>
				<ChatBoxComponent 
                    discussionId={roomUser.userData.wishlists[0].id} 
                    targetUserId={roomUser.userID} 
                    userId={user.id} 
                    userName={user.username} 
                    chatConnection={chatConnection}
                />
				{/* <Button onClick={toggleHideListChat}>Hide Chat</Button> */}
			</div>
		)
    }

    const handleDeleteItem = async (item, user) => {	

		

		const updatedItems = await Promise.all(user.userData.wishlists[0].items.filter((listItem) => {
			return listItem.id !== item.id
		}))

		const updatedUser = {...user, 
			userData: {
				...user.userData,
				wishlists : [{
					...user.userData.wishlists[0],
					items: updatedItems
				}]

			}
		}


		const updatedOpenedRoomUsers = await Promise.all(openedRoomUsers.map((roomUser)=> {
			if(roomUser.userID !== user.userID)
			{
				return roomUser
			}
			else
			{
				return updatedUser
			}
		}))


		setOpenedRoomUsers(updatedOpenedRoomUsers);

	}

    const ListComponentBody = () => {

        return(

            <div>
            <ul className='g-0 px-0' style={{ listStyleType: "none" }}>
            {
                roomUser.userData.wishlists[0].items.map((item) => {
                    return (
                        <li key={item.id}  style={item.hidden ? {display:'none'} :{marginBlock:'15px'}}>
                        {
                            <Card className='px-2 py-2'>
                            {
                                user.id === roomUser.userData.id ?
                                <ItemComponent newName={item.itemName} newPrice={item.price} newLink={item.link} newNotes={item.notes} isOwn={true} theItem={item} itemOwner={roomUser} user={user} handleDelete={handleDeleteItem}/>
                                : 
                                <ItemComponent newName={item.itemName} newPrice={item.price} newLink={item.link} newNotes={item.notes} isOwn={false} theItem={item} itemOwner={roomUser} user={user} handleDelete={handleDeleteItem}/>
                            }  
                            </Card>
                        }
                        </li>)
                })
            }
            </ul>

            {
                //Catch if room user and allow set controls, if so show addItem form
                isRoomUser === true && showRoomUserControl === true ?
                <Togglable 
                    initShowContent={(
                        <Container>
                            <Row className='row d-flex justify-content-between gy-0'>
                                <Col>
                                </Col>
                                <Col className='col d-flex justify-content-end align-content-start'>
                                    <OverlayTrigger
                                            placement="left"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip >
                                                    Add Item
                                                </Tooltip>
                                            }
                                        >
                                        <Button className='button d-flex justify-content-center align-content-center' onClick={toggleHideAddItem}><PlusLg size='2em'/></Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Container>
                    
                    
                    )}
                    initHideContent={addItemHideDiv(roomUser)}
                    ref={togglableRefAddItem}
                />
                    :
                    <span></span>
            }

            {
                user.id === roomUser.userData.id ?  
                <Togglable 
                    initShowContent={(
                        <Container>
                            <Row className='row d-flex justify-content-between gy-0'>
                                <Col>
                                </Col>
                                <Col className='col d-flex justify-content-end align-content-start'>
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip >
                                                Add Item
                                            </Tooltip>
                                        }
                                    >
                                        <Button className='button d-flex justify-content-center align-items-center' onClick={toggleHideAddItem}><PlusLg size='2em'/></Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Container>
                    
                    
                    )}
                    initHideContent={addItemHideDiv(roomUser)}
                    ref={togglableRefAddItem}
                >

                </Togglable>

                    : 

                //TODO !!!!!! Toggle may trigger all chats, check if this will be an issue!!!!
                // <Togglable 
                //     initShowContent={(<Button className='button d-flex justify-content-center align-content-center my-3' onClick={toggleHideListChat}> <h4 className='m-1'>Discuss</h4> <ChatLeftDotsFill size={'2em'} className='d-flex justify-content-center mx-2 my-1'/></Button>)}
                //     initHideContent={(
                //     chatBoxHideDiv()
                //     )}
                //     ref={togglableRefListChat}
                // >

                // </Togglable>





                //TODO  !!!!!!!!!!!!!!!   STYLIZE THIS ACCORDION!!!!!!!!!!!!!!!!!!!!!!
                // For now it works, but down the line, change so it's not a button in header of accordion
                //Ideally something more sleek that expands, and has different color/style of header when active, instead of highlighted blue
                
               <Row sm={1} md={2}>
                   <Col className='p-0'>
                    <Accordion className='justify-content-center align-content-center my-3'>
                            <Accordion.Item  eventKey="0" >
                                <Accordion.Header  className='d-flex justify-content-center align-items-center my-0 py-0'> 
                                    <Alert variant='info' className='button d-flex justify-content-center align-items-center my-0 p-0'> 
                                        <h4 className='m-1'>Discussion</h4> 
                                        <ChatLeftDotsFill size={'2em'} className='d-flex justify-content-center mx-2 my-1'/>
                                    </Alert>
                                </Accordion.Header>
                                
                                <Accordion.Body className='p-1 my-2'>
                                    {chatBoxHideDiv()}
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>

                   </Col>

                  

               </Row>
                
                    
            }
            </div>

        )
    } 


    return(
        
        <div>
            {
                isRoomUser ?

                <Card>
                    <Card.Header className={'d-flex justify-content-center'}><h3>Room</h3> </Card.Header>
                    <Card.Body>
                        <ListComponentBody/>    
                    </Card.Body>
                </Card>

                :

                <div>
                    <Accordion.Header className={'d-flex justify-content-center'}>{isRoomUser ? (<h3>Room</h3>) : roomUser.userData.name}</Accordion.Header>
                    <Accordion.Body >
                        <ListComponentBody/>
                    </Accordion.Body>
                </div>

            }
            
        </div>

    
									
    )

}

export default ListComponent;