import axios from 'axios';

const backURL = `${process.env.REACT_APP_WISHLIST_URL}/api/items`;



let token = null
const setToken = async (newToken) => {
	token = `bearer ${newToken}`

}



const createItem = async (newItem) =>{

const config = {
    headers: {Authorization: token},
}


const response = await axios.post(`${backURL}`, {newItem}, config);
return response.data;


}

const update = async (id, newItem) =>{

  const config = {
    headers: {Authorization: token},
}

  const response = await axios.put(`${backURL}/${id}`, newItem, config);
  return response.data;


}

/*
const getWishlist = async () =>{

  const config = {
      headers: {Authorization: token},
  }

  const response = await axios.get(`${backURL}/self`, config);
  return response.data;


}
*/
const getItems = async (itemIds) => {

  const config = {
    headers: {Authorization: token},
  }

  

  let returnList = await Promise.all(
      //TODO This handles the un-id'd room user, ie room chat and such. Need to give it an id of something
      //TODO Check if there's a way to implement a single call for multiple get/post requests, to make simpler on front end
      //Not a priority, since for this application users are small, however to make larger, better to pass off to back end i think

    itemIds.map(async (itemId) => {
    
      const response = await axios.get(`${backURL}/${itemId}`, config)
      return response.data;
  }))


   return returnList;

}

const removeItem = async (itemId) => {

  const config = {
    headers: {Authorization: token},
  }

  

      //TODO This handles the un-id'd room user, ie room chat and such. Need to give it an id of something
      //TODO Check if there's a way to implement a single call for multiple get/post requests, to make simpler on front end
      //Not a priority, since for this application users are small, however to make larger, better to pass off to back end i think


  const deleteResponse = await axios.delete(`${backURL}/${itemId}`, config)

   return deleteResponse;

}




const exportModules = {createItem, update, setToken, getItems, removeItem};
export default exportModules;