import React from 'react';
import {Form, FormGroup, Button, Container, Row, Col} from 'react-bootstrap'




  //Handles loggining user in
const SignupForm = ({signupName, setSignupName, signupUsername, setSignupUsername, signupPassword, setSignupPassword, handleSignup, toggleHide }) => {

   
	
	


    return (
       

        <Container>
            <Row className='row justify-content-center align-items-center '>
                <Col className='col justify-content-center align-items-center'>
                <h2>Signup</h2>
                </Col>
            </Row>
            <Row className='row d-flex justify-content-center align-items-center g-0 p-0 m-0'>
                <Col>
                    <Form onSubmit={handleSignup}>
                        <FormGroup controlId="signupNameInput">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value = {signupName} placeholder="" onChange={ ({target}) => { setSignupName(target.value)} } />
                            {/* <Form.Text className="text-muted">
                           
                            </Form.Text> */}
                        </FormGroup>

                        <FormGroup controlId="signupUserbameInput">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" value = {signupUsername} placeholder="" onChange={ ({target}) => { setSignupUsername(target.value)} } />
                            {/* <Form.Text className="text-muted">
                           
                            </Form.Text> */}
                        </FormGroup>

                        <FormGroup controlId="signupPasswordInput">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" value = {signupPassword} placeholder="" onChange={ ({target}) => { setSignupPassword(target.value)} } />
                            {/* <Form.Text className="text-muted">
                            
                            </Form.Text> */}
                        </FormGroup>

                        <Container fluid className='mt-3'>
                            <Row className='row justify-content-between align-content-center gap-5'>
                                <Col className=" d-flex col justify-content-start">
                                    <Button variant="primary" id='addRoomButton' type="submit">Signup</Button>
                                </Col>
                                <Col className=" d-flex col justify-content-end px-auto">
                                    <Button variant='link' onClick={() => {toggleHide(); setSignupName(''); setSignupUsername(''); setSignupPassword('');}}>Return to Login</Button>
                                </Col>
                            

                            </Row>

                        </Container>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
	

	
}


export default SignupForm;