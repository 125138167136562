import React, {useState, useImperativeHandle} from 'react';





const Togglable = React.forwardRef((props, ref) => {

    const [visible, setVisible] = useState(false)
	
	const hideWhenVisible = {display: visible ? 'none' : ''}
	const showWhenVisible = {display: visible ? '' : 'none'}
	
	const toggleVisibility = () =>{
        const currentVisible= visible;
		setVisible(!visible)
        return(!visible);
	}
	
	//Let's this method be called from parent component to switch visibility
	//This is because this ISN'T like a class, where you create with 'const Togglable'
	//But instead, <Togglable>...</Togglable>, which are like markups, not objects that act
	//In App, use refHooks to access this function
	useImperativeHandle(ref, () => {
		return{
			toggleVisibility
		}
	})



    //TODO
    //Maybe not use children, and just use props

    //const initShowContent = Array.from(props.initShowContent)
    //const initHideContent = Array.from(props.initHideContent)


    return(

        <div>

            <div style={hideWhenVisible}>
                {
                    //TODO Figure how to get certain children to show before, and hide when either submit or button pressed
                    //props.initShowContent.map((element, index) => {

                //     initShowContent.map((element, index) => {
                //        return (<div key={index}>{element}</div>)
                // })
                    props.initShowContent

                }
                {
                    //<button onClick={toggleVisibility}>{props.buttonLabel}</button>
                }
                
            </div>

            <div style={showWhenVisible}>
                {
                    //props.initHideContent.map((element, index)  => {
                    // initHideContent.map((element, index) => {
                    //   return (<div key={index}>{element}</div>)
                    // })
                    props.initHideContent
                }
                {
				//<button onClick={toggleVisibility}>cancel</button>
                }
            </div>

        </div>

    );

});

export default Togglable;