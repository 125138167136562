import React from 'react'
import {Col, Container, Row, Image, Ratio} from 'react-bootstrap'
import SunMain from '../imgs/SunMain.png'

const Home = () =>{
	
	return(
		
		<div>
			<Container>
				<Row className='bg-light bg-gradient rounded'>
					<Col>
					<h1>Homepage</h1>
					<p>Dreams do come true, just not mine...</p>
					</Col>

				</Row>
				<Row>
					<Col><div className="my-3"><hr></hr></div></Col>
				</Row>
				<Row className='d-flex justify-content-center my-3'>
					<Col></Col>
					<Col sm={6}>
						<Ratio className='ratio-16x9'>
							<Image src={SunMain}  className='my-2 '></Image>
						</Ratio>
					</Col>
					
					<Col>
					</Col>
				
				</Row>
				<Row className='my-5 p-5'></Row>
				<Row className='my-5 p-5'></Row>
				
			</Container>
			
			
			

		</div>
				
	
	)
}

export default Home