import React from 'react';
import {Link} from 'react-router-dom';


// import 'bootstrap/dist/css/bootstrap.min.css'
// import '../customCSS.css'
import Button from 'react-bootstrap/Button';
import { ButtonGroup, Col, Container, Nav, NavItem, Row, Image, Alert } from 'react-bootstrap';
// import footerIcon from '../imgs/SunfishIconFooterAlt.png'
import footerIcon from '../imgs/SunMainAlt.png'

//Custom .scss that i make?
//import '../scss/customStyle.scss'

//import { Navbar } from 'react-bootstrap'


const Footer = () => {

	return(
        <Alert variant='dark' className='p-1'>
        <div className='footer'>
            <Container fluid className=' my-1 p-5'>
                <Row  >
                    <Col className='m-3 d-flex justify-content-center align-items-center'>
                        {/* <h2 style={{color:'white'}}>I would normally be a Logo</h2> */}
                        <Image src={footerIcon} circle style={{width:'200px', height:'200px'}} className='my-2'></Image>
                           
                    </Col>

                    <Col className='d-flex mx-3 justify-content-center align-items-center'>
                        <Alert variant='dark' className='p-1'>
                        <Col className='col footerContent m-1 p-2'>
                            <h3>About This Site</h3>
                        
                            <p>A personal outlet to publish my ideas and showcase projects (and mistakes). Behold the menagerie of code, music, art, and general schmuckery.</p>
                            <p>Background (Haikei.app)</p>
                                <p>Art, website Phillip Chow</p>
                        </Col>
                        </Alert>
                    </Col>
                    

                    <Col className='d-flex mx-3 justify-content-end align-items-center'>
                        <Alert variant='dark' className='p-1'>
                            <Col className='col footerContent m-1 p-2'>
                                <h3>Contact</h3>
                                
                                <h4>email: phill@alcovesun.com</h4>
                                {/* <h4>phone: <b>(###)-###-####</b></h4> */}
                                

                            </Col>
                        </Alert>
                    </Col>
                   
                </Row>
                <Row>
                    <Col></Col>
                    <Col md={5} >
                        {/* <h3>About this site</h3>
                    
                        <p>This site was created to share my thoughts and experience with music. The site will
                        act as personal outlet to publish my ideas and showcase my projects (and mistakes). It is 
                        my hope that others may learn from what I post, or at the very least get a kick at what
                        a pretentious schmuck I am.</p> */}
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </div>
        </Alert>
			
	)
}

export default Footer;