import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Header from './components/Header';
import Footer from './components/Footer';

// import './scss/customStyle.scss'



import {BrowserRouter} from 'react-router-dom'


ReactDOM.render(
  
  <div>
    <Header/>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <Footer/>
  </div>
  
,
  document.getElementById('root')
);


